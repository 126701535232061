import React, { useEffect, useState } from "react";

const WarningAlert = ({ message }) => {
  const [isClose, setIsClose] = useState(false);

  return (
    <>
      {!isClose && (
        <div
          role="alert"
          data-dismissible="alert"
          className="relative flex w-full max-w-screen-md px-4 py-4 text-base text-[#97732C] border-[#97732C]  border bg-[#F9F4D6] rounded-lg font-regular"
        >
          <div className="shrink-0 translate-y-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-6 w-6"
            >
              <path
                fillRule="evenodd"
                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="ml-3 mr-12">
            <h5 className="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal">
              Warning
            </h5>
            <p className="block mt-2 font-sans text-base antialiased font-normal leading-relaxed">
              {message}
            </p>
          </div>

          <button
            data-dismissible-target="alert"
            className="text-[#97732C] !absolute top-3 right-3 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            onClick={() => setIsClose(true)}
          >
            <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default WarningAlert;
