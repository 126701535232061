import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Table } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
// import Logo from "../../../components/logo/Logo";
import apiService from "../../../http";
import { getDiscountedPrice} from "../../../utils";
import ReactDOMServer from 'react-dom/server';
import PrintReport from "./PrintReportSale"; // Import the PrintReport component
const SaleReport = () => {
  const [tableData, setTableData] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    year: null,
    month: null,
    day: null,
  });
  const [reportName, setReportName] = useState("");

  const [columns, setColumns] = useState([
    {
      title: "Sale Id",
      key: "saleId",
      dataIndex: "saleId",
      align: "left",
      width: "100%",
    },
    {
      title: "Customer Name",
      key: "customerName",
      render: (args) => {
        return <p className="!min-w-[200px]">{args.customerName}</p>;
      },
      align: "left",
      width: "100%",
    },
    {
      title: "Total",
      key: "total",
      align: "right",
      render: (args) => {
        const formattedPrice = args.total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      width: "100%",
    },
    {
      title: "Discount",
      key: "discount",
      render: (args) => {
        return `${args.discount}%`;
      },
      align: "right",
      width: "100%",
    },
    {
      title: "Sub Total",
      key: "totalPrice",
      render: (args) => {
        const discountedValue = getDiscountedPrice(args.total, args.discount);
        const formattedPrice = discountedValue.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      align: "right",
      width: "100%",
    },
  ]);

  const addDateColumn = () => {
    setColumns((prevColumns) => {
      const dateColumnIndex = prevColumns.findIndex(
        (column) => column.title === "Date"
      );
      if (selectedDate.day === null) {
        if (dateColumnIndex !== -1) {
          return prevColumns.filter((column) => column.title !== "Date");
        }

        return prevColumns;
      } else {
        const newDateColumn = {
          title: "Date",
          key: "date",
          render: (args) => {
            return `${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`;
          },
          align: "center",
          width: "100%",
        };
        if (dateColumnIndex !== -1) {
          return prevColumns.map((column) =>
            column.title === "Date" ? { ...newDateColumn } : column
          );
        }
        return [...prevColumns, newDateColumn];
      }
    });
  };

  const handlePrintData = () => {
    const printContent = ReactDOMServer.renderToString(<PrintReport tableData={tableData} selectedDate={selectedDate} />);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(
      `<html>
        <head>
          <title>Sales Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>${printContent}</body>
      </html>`
    );
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.onafterprint = function() {
      document.body.removeChild(iframe);
    };
  };

  const handleOnViewClicked = async () => {
    try {
      const { year, month, day } = selectedDate;
      let url = `sale/all-by-date`;

      if (year !== null) {
        url += `?year=${year}`;
        setReportName("Yearly Report");
        if (month !== null) {
          url += `&month=${month}`;
          setReportName("Monthly Report");
          if (day !== null) {
            url += `&day=${day}`;
            setReportName("Daily Report");
          }
        }
      }

      if (url !== `sale/all-by-date`) {
        const salesResponse = await apiService.get(url);
        setTableData(salesResponse);
        addDateColumn();
      } else {
        toast.error(
          "Please select a year, a month, or a specific day to generate the report."
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const customFooter = () => {
    let netTotal = 0;

    for (let i = 0; i < tableData?.length; i++) {
      netTotal += getDiscountedPrice(tableData[i].total, tableData[i].discount);
    }

    return (
      <div className="flex flex-row items-end justify-end w-full gap-16 pr-2 border-y-2">
        <div className="pr-4 text-lg font-bold">Net Total</div>
        <div className="mr-2 text-lg font-bold">
          {netTotal.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      </div>
    );
  };

  const tData = tableData?.map((item, index) => {
    return {
      key: index,
      ...item,
      customFooter,
    };
  });

  return (
    <div className="flex flex-col w-full gap-4 m-6">
      <div className="p-6 border-2 rounded-md">
        <div className="flex flex-col items-start">
          <p className="text-lg font-bold">SALES</p>
          <div className="flex justify-between items-center w-full relative !mt-8">
            <div className="flex flex-row gap-20">
              <DatePicker
                label={"Year"}
                views={["year"]}
                onChange={(newValue) => {
                  let date = new Date(newValue);
                  const selectedValue = {
                    year: date.getFullYear(),
                    month: null,
                    day: null,
                  };
                  setSelectedDate(selectedValue);
                }}
              />
              <DatePicker
                label={"Year and month"}
                views={["year", "month"]}
                onChange={(newValue) => {
                  let date = new Date(newValue);
                  const selectedValue = {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: null,
                  };
                  setSelectedDate(selectedValue);
                }}
              />
              <DatePicker
                label={"Day"}
                views={["year", "month", "day"]}
                onChange={(newValue) => {
                  let date = new Date(newValue);
                  const selectedValue = {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                  };
                  setSelectedDate(selectedValue);
                }}
              />

              <div className="flex flex-col gap-2">
                <button
                  className="text-white btn bg-primary btn-wide"
                  onClick={handlePrintData}
                >
                  PRINT
                </button>
                <button
                  className="text-white btn bg-primary btn-wide"
                  onClick={handleOnViewClicked}
                >
                  VIEW
                </button>
              </div>
            </div>

            {/* Logo Component */}
            {/* <div className="absolute top-0 right-0 flex flex-col">
              <Logo />
            </div> */}
          </div>
        </div>
      </div>
      <p className="text-lg font-bold uppercase ml-7">{reportName}</p>
      <div className="flex flex-col items-center w-full p-6 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={tData}
          style={{ width: "100%" }}
          footer={customFooter}
        />
      </div>
    </div>
  );
};

export default SaleReport;
