import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "../../../components/ui";
import { validateEmail, validatePassword } from "../../../utils";
import apiService from "./../../../http/index";

const UserRegister = () => {
  const inputDataStructure = {
    name: {
      key: "name",
      label: "Name",
      data: "",
      type: "text",
      required: true,
      placeholder: "User Name",
      error: null,
    },
    email: {
      key: "email",
      label: "Email",
      data: "",
      type: "text",
      required: true,
      placeholder: "User Email",
      error: null,
    },
    password: {
      key: "password",
      label: "Password",
      data: "",
      type: "password",
      required: true,
      placeholder: "User Email",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    setInputs(input_list);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValidEmail = validateEmail(inputs.email.data);
    const isValidPassword = validatePassword(inputs.password.data);

    if (!isValidEmail) {
      toast.error("Your entered email is not valid.");
      return;
    }

    console.log("isValidPassword", isValidPassword);

    if (!isValidPassword) {
      toast.error("Your entered password is not valid.");
      return;
    }

    let data = {
      name: inputs.name.data,
      email: inputs.email.data,
      password: inputs.password.data,
    };

    try {
      const result = await apiService.post("user/register", data);

      if (result.message === "Successfully registered") {
        toast.success("User registered successfully!");
        setInputs(inputDataStructure);
      } else {
        toast.error("User registration failed!");
      }
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <>
      <div className="rounded-md border-2 p-6 m-6 w-full">
        <h1 className="font-bold text-lg uppercase">Register</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 !mt-8">
          <form>
            <div>
              <Input input={inputs.name} handleChange={handleChange} />
            </div>

            <div className="mt-4">
              <Input input={inputs.email} handleChange={handleChange} />
            </div>

            <div className="mt-4">
              <Input input={inputs.password} handleChange={handleChange} />
            </div>

            <div className="flex items-center align-baseline content-center justify-between mt-8">
              <Button text={"Register"} handleClick={(e) => handleSubmit(e)} />
            </div>
          </form>

          <aside className="mt-8">
            <div className="bg-gray-100 p-8 rounded">
              <h2 className="font-bold text-2xl">Instructions</h2>
              <ul className="list-disc mt-4 list-inside">
                <li>
                  Users must provide their full name during the account creation
                  process.
                </li>
                <li>
                  All users must provide a valid email address and password to
                  create an account.
                </li>
                <li>
                  Users must not create multiple accounts for the same person.
                </li>
                <li>
                  Ensure user-provided email addresses are in a valid format
                  <span className="font-semibold">
                    {" "}
                    (e.g., user@example.com)
                  </span>
                  .
                </li>
                <li>
                  Implement strong password requirements:
                  <ul className="list-disc ml-8 list-inside font-semibold">
                    <li>At least one uppercase letter (A-Z)</li>
                    <li>At least one lowercase letter (a-z)</li>
                    <li>At least one digit (0-9)</li>
                    <li>At least one special character (#?!@$%^&*-)</li>
                    <li>Minimum length of 8 characters</li>
                  </ul>
                </li>
                <li>
                  Prevent duplicate accounts by checking if the email is already
                  registered.
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default UserRegister;
