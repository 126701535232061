import React from "react";

import CompanyLogo from "../../assets/img/logo.png";

function Logo() {
  return (
    <div className="cursor-pointer w-full">
      <div className="flex h-14">
        <div className="rounded bg-primary p-4">
          <img
            src={CompanyLogo}
            alt="Mirecle Mine"
            width={"120px"}
            height={"120px"}
          />
        </div>
      </div>
    </div>
  );
}

export default Logo;
