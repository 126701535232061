import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import ProductModal from "./ProductModal";
import ProductMaterialModal from "./ProductMaterialModal";
import ProductStockModal from "./ProductStockModal";
import FinishStock from "./finish";

import { Button, Confirm, Input } from "../../../components/ui";
import apiService from "../../../http";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const [productModalState, setProductModalState] = useState(false);
  const [materialModalState, setMaterialModalState] = useState(false);
  const [productStockModal, setProductStockModalState] = useState(false);
  const [finishStockModal, setfinishStockModalState] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setProductModalState(false);
    setConfirmModalState(false);
    setMaterialModalState(false);
    setProductStockModalState(false);
    setfinishStockModalState(false);
    refreshProducts();
  };

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(products);
    } else {
      const data = products.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  const refreshProducts = async () => {
    try {
      const products = await apiService.get("product");

      setProducts(products);
      setFilteredData(products);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    refreshProducts();
  }, []);

  const actionBtns = (product) => {
    return (
      <div className="flex !w-full items-center justify-center space-x-2">
        {/* Example of how to use the Button component */}
        {/* <Button
          text="Delete"
          className="px-3 hover:bg-primary hover:text-white"
          handleClick={() => {
            setSelectedProduct(product);
            // setPartModalState(true);
          }}
        /> */}
  
        <Button
          text="Edit"
          className="px-3 hover:bg-primary hover:text-white"
          handleClick={() => {
            setSelectedProduct(product);
            setProductModalState(true);
          }}
        />
  
        {/* <Button
          text="Materials"
          className="px-3 hover:bg-primary hover:text-white"
          handleClick={() => {
            setSelectedProduct(product);
            setMaterialModalState(true);
          }}
        /> */}
  
        <Button
          text="Stock"
          className="px-3 hover:bg-primary hover:text-white"
          handleClick={() => {
            setSelectedProduct(product);
            setProductStockModalState(true);
          }}
        />
  
        <Button
          text="Finish"
          className="px-3 hover:bg-primary hover:text-white"
          handleClick={() => {
            setSelectedProduct(product);
            setfinishStockModalState(true);
          }}
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Product ID",
      dataIndex: "productId",
      key: "productId",
      align: "left",
      width: "5%",
      // sorter: (a, b) => a.productId.localeCompare(b.productId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "5%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Unit Price",
      align: "right",
      width: "5%",
      render: (arg) => {
        const formattedPrice = arg.unitPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      // sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Contractor Fee",
      align: "right",
      width: "5%",
      render: (arg) => {
        const formattedPrice = arg.contractorFee.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      // sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Skin Quantity",
      dataIndex: "stock",
      align: "center",
      width: "5%",
      // sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Finish Quantity",
      dataIndex: "finishStock",
      align: "center",
      width: "5%",
      // sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Total",
      align: "center",
      width: "5%",
      // sorter: (a, b) => a.stock - b.stock,
      render: (arg) => {
        const totalStock = arg.stock+arg.finishStock;
        return totalStock;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      align: "left",
      width: "5%",
    },

    {
      title: "Operations",
      align: "center",
      width: "15%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {productModalState && (
        <ProductModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {materialModalState && (
        <ProductMaterialModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {productStockModal && (
        <ProductStockModal
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
 {finishStockModal && (
        <FinishStock
          selectedProduct={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
      {confirmModalState && (
        <Confirm
          title="Finish Job"
          confirmMsg="Do you want to finish the product job?"
          actionLabel="OK"
          cancelHandler={handleCloseModal}
          confirmHandler={async () => {
            await apiService.patch("product/status/" + selectedProduct.id);
            toast.success("Product status changed successfully!");
            handleCloseModal();
            setSelectedProduct(null);

          }}
        />
      )}

      <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div>
          
          <Button
            text={"New Product"}
            handleClick={() => setProductModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Products;
