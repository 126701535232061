// PrintReport.jsx
import React from 'react';
import { getDiscountedPrice } from '../../../utils'; // Adjust the path according to your project structure

const PrintReportSale = ({ tableData, selectedDate }) => (
  <div>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src="/logo.png" alt="Logo" style={{ width: '200px' }} />
                <p style={{ fontSize: '15px', margin: '20px 0 10px 0' }}>
                Miracle Mine Investment (PVT) Ltd - Opanayaka
                </p>
                <p style={{ fontSize: '18px', margin: '0' }}>
                077 544 7294, 078 5525 667
                </p>
                <h1>Sales Report</h1>
            </div>
    <table>
      <thead>
        <tr>
          <th>Sale Id</th>
          <th>Customer Name</th>
          <th>Total</th>
          <th>Discount</th>
          <th>Sub Total</th>
          {selectedDate.day !== null && <th>Date</th>}
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => (
          <tr key={index}>
            <td>{item.saleId}</td>
            <td>{item.customerName}</td>
            <td>{item.total.toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
            <td>{`${item.discount}%`}</td>
            <td>{getDiscountedPrice(item.total, item.discount).toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
            {selectedDate.day !== null && <td>{`${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`}</td>}
          </tr>
        ))}
      </tbody>
    </table>
    <div>
      <p>
        Net Total: 
        {tableData.reduce((total, item) => total + getDiscountedPrice(item.total, item.discount), 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}
      </p>
    </div>
  </div>
);

export default PrintReportSale;
