import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Dashboard, Login, Logout } from "../pages";
import { userLoggedOut } from "../redux/actions/userActions";

const Router = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const PrivateRouter = () => {
    if (user.authenticated) {
      const currentTime = Date.now();
      const expTime = user.data.exp * 1000;

      if (expTime < currentTime) {
        dispatch(userLoggedOut());

        return <Navigate to="/login" />;
      }

      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  };
  const PreAuthRouter = () => {
    if (user.authenticated) {
      return <Navigate to="/" />;
    } else {
      return <Outlet />;
    }
  };

  return (
    <Routes>
      <Route element={<PreAuthRouter />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route element={<PrivateRouter />}>
        <Route path="/" element={<Dashboard section={"home"} />} />

        <Route
          path="/materials"
          element={<Dashboard section={"materials"} />}
        />
        <Route path="/products" element={<Dashboard section={"products"} />} />
        <Route path="/jobs" element={<Dashboard section={"jobs"} />} />
        <Route
          path="/suppliers"
          element={<Dashboard section={"suppliers"} />}
        />
        <Route
          path="/contractors"
          element={<Dashboard section={"contractors"} />}
        />
        <Route
          path="/customers"
          element={<Dashboard section={"customers"} />}
        />
        <Route path="/pos" element={<Dashboard section={"pos"} />} />
        <Route path="/sales" element={<Dashboard section={"sales"} />} />
        <Route path="/payments" element={<Dashboard section={"payments"} />} />

        <Route path="/settings/*" element={<Dashboard section={"settings"} />} />
        <Route path="/reports/*" element={<Dashboard section={"reports"} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/*" element={<Dashboard section={"home"} />} />
      </Route>
    </Routes>
  );
};

export default Router;
