import React from "react";
import { useSelector } from "react-redux";
import ListItem from "./listItem";
import CollapseItem from "./CollapseItem";
import {
  FaBars,
  FaCashRegister,
  FaDollyFlatbed,
  FaHome,
  FaUsers,
  FaPeopleCarry,
  FaLuggageCart,
  FaCommentDollar,
  FaShoppingCart,
} from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";

const SideBar = ({ section }) => {
  const userData = useSelector((state) => state.user);

  const navList = [
    {
      title: "Home",
      url: "/",
      icon: <FaHome />,
      selected: section === "home",
      isCollapse: false,
    },
    {
      title: "Contractors",
      url: "/contractors",
      icon: <FaCashRegister />,
      selected: section === "contractors",
      isCollapse: false,
    },
    {
      title: "Suppliers",
      url: "/suppliers",
      icon: <FaPeopleCarry />,
      selected: section === "suppliers",
      isCollapse: false,
    },
    {
      title: "Customers",
      url: "/customers",
      icon: <FaUsers />,
      selected: section === "customers",
      isCollapse: false,
    },
    {
      title: "Materials",
      url: "/materials",
      icon: <FaBars />,
      selected: section === "materials",
      isCollapse: false,
    },
    {
      title: "Products",
      url: "/products",
      icon: <FaDollyFlatbed />,
      selected: section === "products",
      isCollapse: false,
    },
    {
      title: "Jobs",
      url: "/jobs",
      icon: <FaLuggageCart />,
      selected: section === "jobs",
      isCollapse: false,
    },
    {
      title: "POS",
      url: "/pos",
      icon: <FaCashRegister />,
      selected: section === "pos",
      isCollapse: false,
    },
    {
      title: "Sales",
      url: "/sales",
      icon: <FaShoppingCart />,
      selected: section === "sales",
      isCollapse: false,
    },
    {
      title: "Payments",
      url: "/payments",
      icon: <FaCommentDollar />,
      selected: section === "payments",
      isCollapse: false,
    },
    {
      title: "reports",
      url: "/reports",
      icon: <TbReportAnalytics />,
      selected: section === "reports",
      isCollapse: true,
      collapseData: [
        {
          name: "Material",
          url: "/reports/material",
        },
        {
          name: "Stocks",
          url: "/reports/stock",
        },
        {
          name: "Products",
          url: "/reports/product",
        },
        {
          name: "Job Cost",
          url: "/reports/job-cost",
        },
        {
          name: "Sales",
          url: "/reports/sales",
        },
      ],
    },
    {
      title: "settings",
      url: "/settings",
      icon: <IoSettings />,
      selected: section === "settings",
      isCollapse: true,
      collapseData: [
        {
          name: "User Register",
          url: "/settings/user-register",
        },
      ],
    },
  ];

  // Add "Clearance" item for super-admin role
  if (userData.data.role === "super-admin") {
    const settingsItem = navList.find((item) => item.title === "settings");
    if (settingsItem) {
      settingsItem.collapseData.push({
        name: "Clearance",
        url: "/settings/stock-clear",
      });
    }
  }

  return (
    <ul className="w-full space-y-4 overflow-y-auto" style={{ maxHeight: "calc(107vh - 200px)" }}>
      {navList.map((item) =>
        item.isCollapse ? (
          <CollapseItem key={item.url} item={item} />
        ) : (
          <ListItem key={item.url} item={item} />
        )
      )}
    </ul>
  );
};

export default SideBar;
