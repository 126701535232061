import React, { useEffect, useState } from "react";
import apiService from "../../../../http";
import { Table } from "antd";

const ProductMaterialModal = ({ handleClose, selectedProduct }) => {
    const [jobs, setJobs] = useState([]);
    const [summary, setSummary] = useState({
        totalMake: 0,
        totalFinished: 0,
        totalSold: 0,
    });

    const refreshMaterials = async () => {
        const jobList = await apiService.get(
            `product/stock/${selectedProduct.id}`
        );
        let jobSummary = { totalMake: 0, totalFinished: 0, totalSold: 0 };

        jobList.forEach((job) => {
            jobSummary.totalMake += job.makeQuantity;
            jobSummary.totalFinished += job.finishedQuantity;
        });

        setSummary(jobSummary);
        setJobs(jobList);
    };

    useEffect(() => {
        refreshMaterials();
    }, []);

    const columns = [
        {
            title: "#",
            width: "5%",
            render: (arg, record, index) => {
                const rowNumber = index + 1;
                return <p className="text-center">{rowNumber}</p>;
            },
        },

        {
            title: "Job ID",
            dataIndex: "jobId",
            key: "jobId",
            align: "center",
            width: "20%",
            sorter: (a, b) => a.jobId.localeCompare(b.jobId),
        },

        {
            title: "Make Quantity",
            dataIndex: "makeQuantity",
            key: "makeQuantity",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.makeQuantity - b.makeQuantity,
        },
        {
            title: "Finished Quantity",
            dataIndex: "finishedQuantity",
            key: "finishedQuantity",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.finishedQuantity - b.finishedQuantity,
        },
    ];

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[60%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Product stock</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4 flex gap-3">
                        <div className="w-[80%]">
                            <div className="w-full">
                                <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center">
                                    Job list
                                </h3>
                                <Table columns={columns} dataSource={jobs} />
                            </div>
                        </div>
                        <div className="w-[20%]">
                            <h3 className="w-full bg-slate-200 text-slate-500 text-lg text-center mb-4">
                                Sock summery
                            </h3>
                            <div className="mx-4 mb-4 flex flex-col gap-y-3">
                                <p>
                                    Total make :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {summary.totalMake}
                                    </span>
                                </p>
                                <p>
                                    Total finished :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {summary.totalFinished}
                                    </span>
                                </p>
                                <p>
                                    Total sold :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {summary.totalFinished -
                                            selectedProduct.stock}
                                    </span>
                                </p>
                                <p>
                                    Remaining stock :
                                    <span className="bg-primary text-white rounded-lg px-2 text-sm ml-2">
                                        {selectedProduct.stock}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductMaterialModal;
