import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const MaterialModal = ({ handleClose, selectedMaterial }) => {
    const inputDataStructure = {
        title: {
            key: "title",
            label: "Title",
            data: "",
            required: true,
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },

        unitPrice: {
            key: "unitPrice",
            label: "Unit price(LKR)",
            data: "",
            type: "number",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        if (selectedMaterial) {
            let input_list = { ...inputs };

            input_list.title.data = selectedMaterial.title;
            input_list.remarks.data = selectedMaterial.remarks;
            input_list.unitPrice.data = selectedMaterial.unitPrice;

            setInputs(input_list);
        }
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            title: inputs.title.data,
            remarks: inputs.remarks.data,
            unitPrice: inputs.unitPrice.data,
        };

        if (inputs.unitPrice.data <= 0) {
            toast.error("Please enter a valid price!");
            return;
        }

        try {
            if (selectedMaterial) {
                formData.id = selectedMaterial.id;
                await apiService.patch("material", formData);
                toast.success("Material updated successfully!");
            } else {
                await apiService.post("material", formData);
                toast.success("Material added successfully!");
            }

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">
                            {!selectedMaterial && "Add new "}
                            {selectedMaterial && "Edit "}
                            Material
                        </h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Input
                            input={inputs.title}
                            handleChange={handleChange}
                        />
                        {/* <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        /> */}

                        <Input
                            input={inputs.unitPrice}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={selectedMaterial ? "Edit" : "Create"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialModal;
