import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Button, Input, Select } from "../../../../components/ui";

const AddProductStockModal = ({ handleClose }) => {
  const inputDataStructure = {
    product: {
      key: "product",
      label: "Product",
      optTitle: "a product",
      data: "",
      optList: [],
      required: true,
      type: "text",
      error: null,
    },
    quantity: {
      key: "quantity",
      label: "Quantity",
      data: "",
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [isLoading, setIsLoading] = useState(false);

  const buildForm = async () => {
    let input_list = { ...inputs };

    const products = await apiService.get("product");

    const productOptList = products.map((product) => {
      return {
        id: product.id,
        title: `${product.productId} - ${product.title}`,
      };
    });

    input_list.product.optList = productOptList;
    setInputs(input_list);
  };

  useEffect(() => {
    buildForm();
  }, []);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    if (inputs.quantity.data <= 0) {
      toast.error("Please enter a valid quantity!");
      return;
    }

    const formData = {
      product: inputs.product.data,
      quantity: inputs.quantity.data,
    };

    try {
      setIsLoading(true);

      await apiService.post("product/import", formData);

      toast.success("Product imported successfully!");

      handleClose();
      setIsLoading(false);

    } catch (e) {
      toast.error(e);
      setIsLoading(false);

    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">Import product</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="mx-4 mt-2">
            <Select input={inputs.product} handleChange={handleChange} />
            <Input input={inputs.quantity} handleChange={handleChange} />
            {isLoading ? (
                        "Wait..."
                    ) : (
            <Button
              handleClick={handleSubmit}
              className="mt-6"
              text={"Import"}
            />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductStockModal;
