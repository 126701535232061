import React from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";

import HomeSection from "./Home";

import Footer from "./../../components/Footer/index";
import Contractors from "./Contractors";
import Customers from "./Customers";
import Jobs from "./Jobs";
import Materials from "./Materials";
import Payments from "./Payments";
import Pos from "./Pos";
import Products from "./Products";
import Report from "./Reports";
import Sales from "./Sales";
import Settings from "./Settings";
import Suppliers from "./Suppliers";

const Dashboard = ({ section }) => {
  return (
    <div className="overflow-hidden h-screen w-full">
      <NavBar dashboard={true} />
      <div className="flex flex-1 overflow-hidden h-[calc(100vh-64px)]">
        <div className="bg-primary p-6">
          <SideBar section={section} />
        </div>
        <div className="flex flex-1 overflow-y-auto w-full p-2 mb-10">
          {section === "home" && <HomeSection />}
          {section === "materials" && <Materials />}
          {section === "contractors" && <Contractors />}
          {section === "customers" && <Customers />}
          {section === "suppliers" && <Suppliers />}
          {section === "products" && <Products />}
          {section === "jobs" && <Jobs />}
          {section === "payments" && <Payments />}
          {section === "pos" && <Pos />}
          {section === "sales" && <Sales />}
          {section === "reports" && <Report />}
          {section === "settings" && <Settings />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
