import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Moment from "moment";
import apiService from "../../../http";
import { Input } from "../../../components/ui";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const refreshSales = async () => {
    try {
      const sales = await apiService.get("sale");
      setSales(sales);
      setFilteredData(sales);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(sales);
    } else {
      const data = sales.filter((item) => {
        const lowerCaseTitle = item.customerName.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  useEffect(() => {
    refreshSales();
  }, []);

  const columns = [
    {
      title: "Sale Id",
      dataIndex: "saleId",
      key: "saleId",
      align: "left",
      width: "5%",
    },
    {
      title: "Customer Name",
      align: "left",
      width: "20%",
      render: (arg) => {
        return (
          <div className="flex flex-col items-start">
            <p>{arg.customerName}</p>
          </div>
        );
      },
    },
    {
      title: "Customer Id",
      align: "left",
      width: "10%",
      render: (arg) => {
        return (
          <div className="flex flex-col items-start">
            <p>{arg.customerId}</p>
          </div>
        );
      },
    },
    {
      title: "Total",
      align: "right",
      width: "10%",
      render: (arg) => {
        const formattedPrice = arg.total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
    },
    {
      title: "Discount",
      align: "right",
      width: "5%",
      render: (arg) => {
        return arg.discount + "%";
      },
    },
    {
      title: "Sub Total",
      align: "right",
      width: "10%",
      render: (arg) => {
        const subTotal = arg.total - (arg.total * arg.discount) / 100;
        const formattedPrice = subTotal.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
    },
    {
      title: "Date",
      align: "center",
      width: "10%",
      render: (arg) => {
        const date = Moment(arg.createdAt);
        const formattedDatetime = date.format("YYYY-MM-DD");
        return formattedDatetime;
      },
    },
    // {
    //   title: "Invoice",
    //   align: "center",
    //   width: "10%",
    //   render: (arg) => {
    //     return (
    //       <a
    //         target="_blank"
    //         rel="noreferrer"
    //         href={arg.invoice}
    //         className="px-2 py-1 text-white rounded-lg bg-primary"
    //       >
    //         view
    //       </a>
    //     );
    //   },
    // },
    {
      title: "Actions",
      align: "center",
      width: "10%",
      render: (arg) => {
        return (
          <button
            onClick={() => handlePrint(arg)}
            className="px-2 py-1 text-white rounded-lg bg-secondary"
          >
            Print
          </button>
        );
      },
    },
  ];

  const handlePrint = (invoice) => {
    const formattedDate = Moment(invoice.createdAt).format("YYYY-MM-DD");
    const discountAmount = (invoice.total * invoice.discount) / 100;
    const netTotal = invoice.total - discountAmount;
    const taxAmount = 0; // calculate if needed
    const grandTotal = netTotal; // update if needed
    const printContent = `<table style="width: 100%;">
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        ${invoice.items
          .map(
            (item) => `
          <tr>
            <td>${item.title}</td>
            <td>${item.quantity}</td>
            <td>${item.price.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</td>
          </tr>`
          )
          .join("")}
      </tbody>
    </table>`;
  
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      `<html><head><title>Invoice</title>
      <style>
          table, th, td {
              border: 1px solid black;
              border-collapse: collapse;
              padding: 8px;
          }
          th {
              background-color: #f2f2f2;
          }
          img {
              width: 150px; /* Adjust the width as needed */
              display: block;
              margin: 0 auto; /* Center the logo */
          }
          .print-content {
              text-align: center;
              margin-bottom: 20px;
          }
          .print-content p {
              font-family: Sinhala, Arial;
              font-size: 15px;
              margin: 20px 0 10px 0;
          }
          h1, h3 {
              text-align: center;
          }
          .details {
              display: flex;
              justify-content: space-between;
              margin: 20px;
          }
          .left, .right {
              width: 45%;
          }
          .right {
              text-align: right;
          }
      </style>
      </head><body>
      <div class="print-content">
          <img src="/logo.png" alt="Logo" />
          <p>
              තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල් ෆැබ්‍රිකේශන් වැඩ
          <br>
              විලකොළදෙනිය, මලංගම, රත්නපුර
          <br>
              045 2121750, 074 0327850
          </p>
          -------------------------------------------------------------------------
      </div>
  
      <h3>Invoice</h3>
      <p>Invoice Number: ${invoice.saleId}
      <br>
      Invoice Date: ${formattedDate}
      <br>
      Customer Name: ${invoice.customerName}
      <br>
      Customer Telephone: ${invoice.customerPhone}
      </p>
      <br>
  
      ${printContent}
  
      <div class="details">
          <div class="left">
              
          </div>
          <div class="right">
              <p>Total Value: ${invoice.total.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}<br>
              Discount: ${invoice.discount}%<br>
              Net Total: ${netTotal.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}<br>
             
          </div>
      </div>
      <p>Invoiced By Miracle Mine.</p>
      </body></html>`
    );
    printWindow.document.write(
      `<script>
          window.onload = function() { 
              window.print(); 
              window.onafterprint = function() { window.close(); }; 
          };
      </script>`
    );
    printWindow.document.close();
  };
  
  return (
    <section className="w-full mt-6">
      <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div></div>
        <div></div>
        <div>
          <Input
            input={inputs.searchString}
            handleChange={handleChange}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        style={{ width: "100%" }}
      />
    </section>
  );
};

export default Sales;
