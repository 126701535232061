import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
import Logo from "../../../components/logo/Logo";
import RadioButton from "../../../components/ui/RadioButton";
import SelectProduct from "../../../components/ui/SelectProduct";
import apiService from "../../../http";
// import { printPreview } from "../../../utils";
import ReactDOMServer from 'react-dom/server';
import PrintReport from "./PrintReport"; // Adjust the import path as needed

function MaterialReport() {
  const [isAllProductSelected, setIsAllProductsSelected] = useState(false);
  const [isAllSuppliersSelected, setIsAllSuppliersSelected] = useState(false);

  const [productsData, setProductsData] = useState(null);
  const [supplierData, setSupplierData] = useState(null);

  const [tableData, setTableData] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    year: null,
    month: null,
    day: null,
  });

  const [filterType, setFilterType] = useState("All");

  const getAllData = async () => {
    try {
      const Productresponse = await apiService.get("job");
      const supplierresponse = await apiService.get("supplier");
      setProductsData(Productresponse);
      setSupplierData(supplierresponse);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAllProductSelected = () => {
    setIsAllProductsSelected(!isAllProductSelected);
  };
  const handleAllSupplierSelected = () => {
    setIsAllSuppliersSelected(!isAllSuppliersSelected);
  };

  const handleOnViewClicked = async () => {
    if (filterType === "Filter By Jobs") {
      try {
        const materialResponse = await apiService.get(
          `product/material/${selectedProduct.id}`
        );
        setTableData(materialResponse);
      } catch (err) {
        console.log(err);
      }
    }
    if (filterType === "Filter By Supplier") {
      try {
        const materialResponse = await apiService.get(
          `material/materials?supplierId=${selectedSupplier.supplierId}&fromDate=${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`
        );
        setTableData(materialResponse);
      } catch (err) {
        console.log(err);
      }
    }

    if (filterType === "All") {
      try {
        const materialResponse = await apiService.get(`material/`);
        setTableData(materialResponse);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setTableData(null);
  }, [filterType]);

  const customFooter = () => {
    let totalQty = 0;
    let TotalNetPrice = 0;

    for (let i = 0; i < tableData?.length; i++) {
      totalQty += tableData[i]["stockCount"];
      TotalNetPrice += tableData[i]["stockCount"] * tableData[i]["unitPrice"];
    }

    return (
      <div className="flex flex-row items-end justify-end w-full gap-16 pr-2 border-y-2">
        <div className="flex gap-3">
          <div className="text-lg font-bold">Total Qty:</div>
          <div className="pr-16 text-lg font-bold">{totalQty}</div>
        </div>
        <div className="flex gap-3">
          <div className="text-lg font-bold">Total Net Price:</div>
          <div className="mr-2 text-lg font-bold">
            {TotalNetPrice.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (arg, records, index) => {
        return <p className="text-center">{index + 1}</p>;
      },
    },
    {
      title: "Material ID",
      key: "materialId",
      render: (args) => {
        return <p className="w-[100px] text-left">{args.materialId}</p>;
      },
      align: "left",
      width: "10%",
    },
    {
      title: "Material Name",
      key: "materialName",
      render: (args) => {
        return <p className="min-w-[250px] text-left">{args.title}</p>;
      },
      align: "left",
      width: "100%",
    },
    {
      title: "Quantity",
      key: "materialName",
      render: (args) => {
        return args.stockCount;
      },
      align: "center",
      width: "100%",
    },
    {
      title: "Unit Price",
      key: "unitPrice",
      align: "right",
      render: (args) => {
        const formattedPrice = args.unitPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      width: "100%",
    },
    {
      title: "Total Price",
      key: "totalPrice",
      align: "right",
      render: (args) => {
        const total = Number(args.stockCount) * Number(args.unitPrice);
        const formattedPrice = total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      width: "100%",
    },
  ];

  const tData = tableData?.map((item, index) => {
    return {
      key: index,
      ...item,
      customFooter,
    };
  });

  const handlePrintData = () => {
    const printContent = ReactDOMServer.renderToString(<PrintReport tableData={tableData} />);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(
        `<html>
            <head>
                <title>Material Stock Report</title>
                <style>
                    /* Add any styles needed for printing */
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #000;
                        padding: 8px;
                        text-align: left;
                    }
                </style>
            </head>
            <body>${printContent}</body>
        </html>`
    );
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.onafterprint = function() {
        document.body.removeChild(iframe);
    };
};



  const handleRadioClick = (selectedOption) => {
    setFilterType(selectedOption);
  };

  const radioBtnOptions = ["All", "Filter By Jobs", "Filter By Supplier"];

  return (
    <div className="flex flex-col w-full gap-4 m-6">
      <div className="p-6 border-2 rounded-md">
        <div className="flex flex-col items-start">
          <p className="text-lg font-bold">MATERIAL STOCK</p>
          <div className="mt-8">
            <RadioButton
              options={radioBtnOptions}
              selectedOption={filterType}
              onClick={handleRadioClick}
            />
          </div>
          <div className="flex justify-between items-center w-full relative !mt-8">
            {/* product and supplier Selection Section */}
            <div className="flex flex-row gap-20">
              <div className={filterType === "All" && "hidden"}>
                <div
                  className={filterType === "Filter By Supplier" && "hidden"}
                >
                  <SelectProduct
                    reportName={"JOBS"}
                    titleData={"Select Job ID"}
                    ListOfData={productsData}
                    selectedValue={selectedProduct}
                    handleSelectedValue={setSelectedProduct}
                    checkValue={isAllProductSelected}
                    handleOnChecked={handleAllProductSelected}
                  />
                </div>
                <div
                  className={`flex flex-col gap-2 ${
                    filterType === "Filter By Jobs" && "hidden"
                  }`}
                >
                  <SelectProduct
                    reportName={"COMMON"}
                    titleData={"Select Supplier"}
                    ListOfData={supplierData}
                    selectedValue={selectedSupplier}
                    handleSelectedValue={setSelectedSupplier}
                    checkValue={isAllSuppliersSelected}
                    handleOnChecked={handleAllSupplierSelected}
                  />
                  <div className="flex w-[376px] justify-between">
                    <p>From </p>
                    <DatePicker
                      slotProps={{ textField: { size: "small" } }}
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        let date = new Date(newValue);
                        const selectedValue = {
                          year: date.getFullYear(),
                          month: date.getMonth() + 1,
                          day: date.getDate(),
                        };
                        setSelectedDate(selectedValue);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* print and view button sections */}
              <div className="flex flex-col gap-2">
                <button
                  className="text-white btn bg-primary btn-wide"
                  onClick={handlePrintData}
                >
                  PRINT
                </button>
                <button
                  className="text-white btn bg-primary btn-wide"
                  onClick={handleOnViewClicked}
                >
                  VIEW
                </button>
              </div>
            </div>

            {/* Logo Component */}
            <div className="absolute top-0 right-0 flex flex-col">
              <Logo />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center w-full p-6 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={tData}
          style={{ width: "100%" }}
          footer={customFooter}
        />
      </div>
    </div>
  );
}

export default MaterialReport;
