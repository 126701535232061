import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Confirm, WarningAlert } from "../../../components/ui";
import apiService from "../../../http";
import ImportMaterialModal from "../Materials/ImportMaterialModal";
import AddProductStockModal from "../Products/AddProductStockModal";

const StockClear = () => {
  const [materialConfirmModalState, setMaterialConfirmModalState] =
    useState(false);
  const [productConfirmModalState, setProductConfirmModalState] =
    useState(false);

  const handleMaterialClearing = async () => {
    try {
      const result = await apiService.post("material/clear-stock");
      toast.success("All material stock are cleared!");
    } catch (e) {
      toast.error(e);
    }

    setMaterialConfirmModalState(false);
  };

  const handleProductClearing = async () => {
    try {
      const result = await apiService.post("product/clear-stock");
      toast.success("All product stock are cleared!");
    } catch (e) {
      toast.error(e);
    }

    setProductConfirmModalState(false);
  };

  // START: Add section
  const [importMaterialModalState, setImportMaterialModalState] =
    useState(false);
  const [importProductModalState, setImportProductModalState] = useState(false);

  const handleCloseModal = () => {
    setImportMaterialModalState(false);
    setImportProductModalState(false);
  };
  // END: Add section

  return (
    <>
      <div className="rounded-md border-2 p-6 m-6 w-full">
        <h1 className="font-bold text-lg uppercase">Clear Stock</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 !mt-8">
          <div className="flex flex-col mr-5">
            <Button
              text={"Clear Material Stock"}
              handleClick={(e) => {
                e.preventDefault();
                setMaterialConfirmModalState(true);
              }}
              className="h-20 rounded-lg hover:bg-red-700"
            />
            <WarningAlert message="This action will clear the material stock." />
          </div>
          <div className="flex flex-col mr-5">
            <Button
              text={"Clear Product Stock"}
              handleClick={(e) => {
                e.preventDefault();
                setProductConfirmModalState(true);
              }}
              className="h-20 rounded-lg hover:bg-red-700"
            />
            <WarningAlert message="This action will clear the all product stock." />
          </div>
        </div>
        <hr className="border-gray-500 border-2 opacity-20 rounded-xl mt-20" />
        {/* START: Add Stock Section */}
        <div>
          <h1 className="font-bold text-lg uppercase mt-5">Add Stock</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 !mt-8">
            <div className="flex flex-col mr-5">
              <Button
                className="h-20 rounded-lg"
                text={"Import Material"}
                handleClick={() => setImportMaterialModalState(true)}
              />
            </div>
            <div className="flex flex-col mr-5">
              <Button
                className="h-20 rounded-lg"
                text={"Import Product"}
                handleClick={() => setImportProductModalState(true)}
              />
            </div>
          </div>
        </div>
        {/* END: Add Stock Section */}
      </div>

      {/* START: All Modal */}

      {/* Confirmation modal */}
      {productConfirmModalState && (
        <Confirm
          title="Clear product stock"
          actionLabel="Ok"
          confirmMsg="Are you sure , you want to clear all the product stock?"
          confirmHandler={handleProductClearing}
          cancelHandler={() => {
            setProductConfirmModalState(false);
          }}
        />
      )}
      {materialConfirmModalState && (
        <Confirm
          title="Clear material stock"
          actionLabel="Ok"
          confirmMsg="Are you sure , you want to clear all the material stock?"
          confirmHandler={handleMaterialClearing}
          cancelHandler={() => {
            setMaterialConfirmModalState(false);
          }}
        />
      )}

      {/* Form modal */}
      {importMaterialModalState && (
        <ImportMaterialModal handleClose={handleCloseModal} noSupplier />
      )}
      {importProductModalState && (
        <AddProductStockModal handleClose={handleCloseModal} />
      )}

      {/* END: All modal */}
    </>
  );
};

export default StockClear;
