import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";

import MaterialModal from "./MaterialModal";

import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";
import ImportMaterialModal from "./ImportMaterialModal";

const Materials = () => {
  const [materials, setMaterials] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataModalState, setDataModalState] = useState(false);
  const [importModalState, setImportModalState] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const handleEdit = (material) => {
    setSelectedMaterial(material);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("material/" + selectedMaterial.id);
      setConfirmModalState(false);
      refreshMaterials();
      setSelectedMaterial(null);
      toast.success("Material deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    setDataModalState(false);
    setImportModalState(false);
    setConfirmModalState(false);
    setSelectedMaterial(null);
    refreshMaterials();
  };

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const refreshMaterials = async () => {
    try {
      const materials = await apiService.get("material");
      setMaterials(materials);
      setFilteredData(materials);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(materials);
    } else {
      const data = materials.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  useEffect(() => {
    refreshMaterials();
  }, []);

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            await apiService.patch(`material/${item.id}`);
            refreshMaterials();
          } catch (e) {
            console.log(e);
          }
        }}
        className="px-2 py-1 mt-1 ml-2 text-center text-white rounded-lg cursor-pointer bg-secondary"
      >
        {status}
      </span>
    );
  };

  const actionBtns = (material) => {
    return (
      <div className="flex">
        {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(material)}
                /> */}
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedMaterial(material);
            handleEdit(material);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "materialId",
      key: "materialId",
      align: "left",
      width: "10%",
      // sorter: (a, b) => a.materialId.localeCompare(b.materialId),
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "20%",
    },
    // {
    //     title: "Remarks",
    //     align: "center",
    //     width: "30%",
    //     render: (arg) => {
    //         return arg.remarks ? arg.remarks : "N/A";
    //     },
    // },

    {
      title: "Unit Price",
      align: "right",
      width: "20%",
      render: (arg) => {
        const formattedPrice = arg.unitPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      // sorter: (a, b) => a.unitPrice - b.unitPrice,
    },

    {
      title: "Stock Count",
      dataIndex: "stockCount",
      key: "stockCount",
      align: "center",
      width: "20%",
    },

    {
      title: "Status",
      align: "center",
      width: "20%",
      render: (arg) => status(arg),
    },

    {
      title: "Operations",
      align: "center",
      width: "30%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <MaterialModal
          selectedMaterial={selectedMaterial}
          handleClose={handleCloseModal}
        />
      )}
      {importModalState && (
        <ImportMaterialModal handleClose={handleCloseModal} />
      )}
      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

<div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
  <div className="flex space-x-2">
    <Button
      text={"Add New Material"}
      handleClick={() => setDataModalState(true)}
    />
    <Button
      text={"Import Material"}
      handleClick={() => setImportModalState(true)}
    />
  </div>
  <div></div>
  <div>
    <Input input={inputs.searchString} handleChange={handleChange} />
  </div>
</div>

      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Materials;
