import React, { useState } from "react";

const SelectProduct = (props) => {
  const {
    reportName,
    titleData,
    ListOfData,
    selectedValue,
    handleSelectedValue,
    checkValue,
    handleOnChecked,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handler = () => {
    setIsOpen(!isOpen);
  };

  const maniDivClass = "flex gap-6";
  const btnClass = "btn w-[250px]";
  const ulClass =
    "p-2 w-[250px] text-center shadow menu dropdown-content z-[1] rounded-box bg-secondary";
  const liClass = "!cursor-pointer !p-2 !gap-2 hover:!bg-white !rounded-lg";

  if (reportName === "PRODUCT-JOB-STOCK") {
    return (
      <div className={maniDivClass}>
        <p>{titleData}</p>
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className={btnClass}
            onClick={handler}
          >
            {selectedValue?.title != null
              ? selectedValue.title
              : "Please Choose"}
          </div>
          {isOpen && (
            <ul tabIndex={0} className={ulClass}>
              {ListOfData?.map((item, index) => (
                <li
                  key={index}
                  className={liClass}
                  value={selectedValue?.id}
                  onClick={() => {
                    handleSelectedValue(item);
                    handler();
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }

  if (reportName === "JOB-COST") {
    return (
      <div className={maniDivClass}>
        <p>{titleData}</p>
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className={btnClass}
            onClick={handler}
          >
            {selectedValue?.jobId != null
              ? selectedValue.jobId
              : "Please Choose"}
          </div>
          {isOpen && (
            <ul tabIndex={0} className={ulClass}>
              {ListOfData?.map((item, index) => (
                <li
                  key={index}
                  className={liClass}
                  value={selectedValue?.jobId}
                  onClick={() => {
                    handleSelectedValue(item);
                    handler();
                  }}
                >
                  {item.jobId}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }

  if (reportName === "MATERIAL-STOCK") {
    return (
      <div className={maniDivClass}>
        <p>{titleData}</p>
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className={btnClass}
            onClick={handler}
          >
            {selectedValue?.title != null
              ? selectedValue.title
              : "Please Choose"}
          </div>
          {isOpen && (
            <ul tabIndex={0} className={ulClass}>
              {ListOfData?.map((item, index) => (
                <li
                  key={index}
                  className={liClass}
                  value={selectedValue?.id}
                  onClick={() => {
                    handleSelectedValue(item);
                    handler();
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>
        <span>
          All{" "}
          <input
            type="checkbox"
            checked={checkValue}
            onChange={handleOnChecked}
          />
        </span>
      </div>
    );
  }
  
  if (reportName === "COMMON") {
    return (
      <div className={maniDivClass}>
        <p>{titleData}</p>
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className={btnClass}
            onClick={handler}
          >
            {selectedValue?.title != null
              ? selectedValue.title
              : "Please Choose"}
          </div>
          {isOpen && (
            <ul tabIndex={0} className={ulClass}>
              {ListOfData?.map((item, index) => (
                <li
                  key={index}
                  className={liClass}
                  value={selectedValue?.id}
                  onClick={() => {
                    handleSelectedValue(item);
                    handler();
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
  if (reportName === "JOBS") {
    return (
      <div className={maniDivClass}>
        <p>{titleData}</p>
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className={btnClass}
            onClick={handler}
          >
            {selectedValue?.title != null
              ? selectedValue.title
              : "Please Choose"}
          </div>
          {isOpen && (
            <ul tabIndex={0} className={ulClass}>
              {ListOfData?.map((item, index) => (
                <li
                  key={index}
                  className={liClass}
                  value={selectedValue?.id}
                  onClick={() => {
                    handleSelectedValue(item);
                    handler();
                  }}
                >
                  {item.jobId}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
};

export default SelectProduct;
