// PrintReport.jsx
import React from 'react';

const PrintReportProducts = ({ tableData }) => (
  <div>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src="/logo.png" alt="Logo" style={{ width: '200px' }} />
                <p style={{ fontSize: '15px', margin: '20px 0 10px 0' }}>
                Miracle Mine Investment (PVT) Ltd - Opanayaka
                </p>
                <p style={{ fontSize: '18px', margin: '0' }}>
                077 544 7294, 078 5525 667
                </p>
                <h1>Product Job Report</h1>
            </div>
    <table>
      <thead>
        <tr>
          <th>Job Id</th>
          <th>Product ID</th>
          <th>Product Name</th>
          <th>Contractor Fee</th>
          <th>Finished Quantity</th>
          <th>Unit Price</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => (
          <tr key={index}>
            <td>{item.jobId}</td>
            <td>{item.product.productId}</td>
            <td>{item.product.title}</td>
            <td>{item.product.contractorFee.toLocaleString("en-US", { minimumFractionDigits: 2 })}</td>
            <td>{item.finishedQuantity}</td>
            <td>{item.product.unitPrice.toLocaleString("en-US", { minimumFractionDigits: 2 })}</td>
            <td>{(item.finishedQuantity * item.product.unitPrice).toLocaleString("en-US", { minimumFractionDigits: 2 })}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <div>
      <p>Total Finished Quantity: {tableData.reduce((total, item) => total + item.finishedQuantity, 0)}</p>
      <p>Total Net Price: {tableData.reduce((total, item) => total + item.finishedQuantity * item.product.unitPrice, 0).toLocaleString("en-US", { minimumFractionDigits: 2 })}</p>
    </div>
  </div>
);

export default PrintReportProducts;
