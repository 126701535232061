import React from 'react';

const PrintReport = ({ tableData }) => (
    <div>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src="/logo.png" alt="Logo" style={{ width: '200px' }} />
                <p style={{ fontSize: '15px', margin: '20px 0 10px 0' }}>
                Miracle Mine Investment (PVT) Ltd - Opanayaka
                </p>
                <p style={{ fontSize: '18px', margin: '0' }}>
                077 544 7294, 078 5525 667
                </p>
                <h1>Legacy Stock Report</h1>
            </div>
        <table>
            <thead>
                <tr>
                    <th>Product ID</th>
                    <th>Product Name</th>
                    <th>Stock Quantity</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.productId}</td>
                        <td>{item.title}</td>
                        <td>{item.stock}</td>
                        <td>{item.unitPrice.toLocaleString("en-US", { minimumFractionDigits: 2 })}</td>
                        <td>{(item.stock * item.unitPrice).toLocaleString("en-US", { minimumFractionDigits: 2 })}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        <div>
            <p>Total: {tableData.reduce((total, item) => total + item.stock * item.unitPrice, 0).toLocaleString("en-US", { minimumFractionDigits: 2 })}</p>
        </div>
    </div>
);

export default PrintReport;
