import React from "react";

import { Route, Routes } from "react-router-dom";

import HomeSection from "../Home";
import Sales from "../Sales";
import StockReport from "./StockReport";
import JobCostReport from "./jobCostReport";
import MaterialReport from "./materialReport";
import ProductReport from "./productReport";
import SaleReport from "./saleReport";

function index() {
  return (
    <Routes>
      {/* add different routes athat are needed for each Report type */}
      <Route path="/stock" element={<StockReport />} />
      <Route path="/material" element={<MaterialReport />} />
      <Route path="/product" element={<ProductReport />} />
      <Route path="/job-cost" element={<JobCostReport />} />
      <Route path="/sales" element={<SaleReport />} />
      <Route path="/" element={<Sales />} />
      <Route path="/*" element={<HomeSection />} />
    </Routes>
  );
}

export default index;
