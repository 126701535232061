export const buildContractStatus = (
  transactionList,
  paymentList,
  setTransactionStatus,
  setTransactionSummary
) => {
  let transactionStatusList = [];

  let totalGiven = 0;
  let totalCompleted = 0;
  let totalPaid = 0;

  transactionList.forEach((transaction) => {
    let index = transactionStatusList.findIndex((item) => {
      return item.contractor.id === transaction.contractor.id;
    });

    if (index === -1) {
      const newLen = transactionStatusList.push({
        given: 0,
        completed: 0,
        paid: 0,
        contractor: transaction.contractor,
      });

      index = newLen - 1;
    }

    switch (transaction.type) {
      case "assign":
        transactionStatusList[index].given += transaction.quantity;
        totalGiven += transaction.quantity;
        break;
      case "return":
        transactionStatusList[index].completed += transaction.quantity;
        totalCompleted += transaction.quantity;
        break;
      case "reject":
        transactionStatusList[index].completed -= transaction.quantity;
        totalCompleted -= transaction.quantity;
        break;

      default:
        break;
    }
  });
  paymentList.forEach((payment) => {
    totalPaid += payment.quantity;

    let index = transactionStatusList.findIndex((item) => {
      return item.contractor.id === payment.contractor.id;
    });
    transactionStatusList[index].paid += payment.quantity;
  });
  setTransactionStatus(transactionStatusList);
  const summary = {
    totalGiven,
    totalCompleted,
    totalPaid,
  };

  setTransactionSummary(summary);
};
