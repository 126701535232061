import React, { useEffect, useState } from "react";
import { Input, Select, Button } from "../ui";
import { toast } from "react-toastify";
import apiService from "../../http";

const MaterialModal = ({ handleClose, selectedMaterial }) => {
    const inputDataStructure = {
        currentPassword: {
            key: "currentPassword",
            label: "Current password",
            data: "",
            required: true,
            type: "password",
            error: null,
        },
        newPassword: {
            key: "newPassword",
            label: "New Password",
            data: "",
            required: true,
            type: "password",
            error: null,
        },
        newPasswordConf: {
            key: "newPasswordConf",
            label: "Confirm new password",
            data: "",
            required: true,
            type: "password",
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        const formData = {
            currentPassword: inputs.currentPassword.data,
            newPassword: inputs.newPassword.data,
            newPasswordConf: inputs.newPasswordConf.data,
        };

        try {
            await apiService.patch("/user/change-password", formData);
            toast.success("Password updated successfully!");

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Update password</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Input
                            input={inputs.currentPassword}
                            handleChange={handleChange}
                        />
                        <Input
                            input={inputs.newPassword}
                            handleChange={handleChange}
                        />

                        <Input
                            input={inputs.newPasswordConf}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={"Update"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialModal;
