import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import SupplierModal from "./SupplierModal";
import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dataModalState, setDataModalState] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleEdit = (supplier) => {
    setSelectedSupplier(supplier);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    
    try {
      setIsLoading(true);
      await apiService.delete("supplier/" + selectedSupplier.id);
      setConfirmModalState(false);
      refreshSuppliers();
      setSelectedSupplier(null);
      toast.success("Supplier deleted successfully");
      setIsLoading(false);

    } catch (e) {
      toast.error(e);
      setIsLoading(false);

    }
  };

  const handleCloseModal = () => {
    refreshSuppliers();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedSupplier(null);
  };

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(suppliers);
    } else {
      const data = suppliers.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  const refreshSuppliers = async () => {
    try {
      const suppliers = await apiService.get("supplier");
      setSuppliers(suppliers);
      setFilteredData(suppliers);

    } catch (e) {
      toast.error(e);

    }
  };

  useEffect(() => {
    refreshSuppliers();
  }, []);

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            setIsLoading(true);
            await apiService.patch(`supplier/${item.id}`);
            refreshSuppliers();
            setIsLoading(false);

          } catch (e) {
            console.log(e);
            setIsLoading(false);

          }
        }}
        className="px-2 py-1 mt-1 ml-2 text-center text-white rounded-lg cursor-pointer bg-secondary"
      >
        {status}
      </span>
    );
  };

  const actionBtns = (supplier) => {
    return (
      <div className="flex">
        {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(supplier)}
                /> */}
                {isLoading ? (
                        "Wait..."
                    ) : (
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            setConfirmModalState(true);
          }}
        />)}
{isLoading ? (
                        "Wait..."
                    ) : (
        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedSupplier(supplier);
            handleEdit(supplier);
          }}
        />)}
      </div>
    );
  };

  //   const specifications = (specs) => {
  //     const classes =
  //       "text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg";

  //     return (
  //       <div className="flex flex-col items-start gap-2">
  //         {specs.thickness && (
  //           <p className={classes}>Thickness: {specs.thickness} mm</p>
  //         )}
  //         {specs.length && <p className={classes}>Length: {specs.length} ft</p>}
  //         {specs.width && <p className={classes}>Width: {specs.width} ft</p>}
  //         {specs.diameter && (
  //           <p className={classes}>Diameter: {specs.diameter} in</p>
  //         )}
  //         {specs.weight && <p className={classes}>Weight: {specs.weight} kg</p>}
  //       </div>
  //     );
  //   };
  //   const stockCount = (supplier) => {
  //     if (supplier.priceCalcMethod === "weight-based") {
  //       return supplier.stock + " kg";
  //     } else if (supplier.priceCalcMethod === "quantity-based") {
  //       return supplier.stock;
  //     } else if (supplier.priceCalcMethod === "length-based") {
  //       const lengthPerQuantity = supplier.specifications.length;
  //       const fullCount = Math.floor(supplier.stock / lengthPerQuantity);
  //       const remainingCount = supplier.stock % lengthPerQuantity;

  //       return (
  //         <div className="flex flex-col items-start">
  //           <p>{"Full : " + fullCount}</p>
  //           <p>{"Remaining : " + remainingCount + " ft"}</p>
  //         </div>
  //       );
  //     }
  //   };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "supplierId",
      key: "supplierId",
      align: "left",
      width: "10%",
      // sorter: (a, b) => a.supplierId.localeCompare(b.supplierId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "20%",
    },
    // {
    //     title: "Remarks",
    //     align: "center",
    //     width: "20%",
    //     dataIndex: "remarks",
    //     key: "remarks",
    // },
    {
      title: "Phone Number",
      align: "left",
      width: "20%",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      align: "left",
      width: "20%",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Status",
      align: "center",
      width: "20%",
      render: (arg) => status(arg),
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <SupplierModal
          selectedSupplier={selectedSupplier}
          handleClose={handleCloseModal}
        />
      )}

      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div>
        {isLoading ? (
                        "Wait..."
                    ) : (
          <Button
            text={"Add New Supplier"}
            handleClick={() => setDataModalState(true)}
          />
                    )}
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Suppliers;
