import React from "react";

const Button = ({ handleClick, className, children,text, disabled = false }) => {
  return (
    <button
      onClick={handleClick}
      className={
        "border-2  px-12 py-2 font-semibold bg-secondary text-white" +
        "  hover:bg-primary  cursor-pointer " +
        "rounded-md mb-4 " +
        className
      }
      disabled={disabled}
    >
      {children || text}
    </button>
  );
};

export default Button;
