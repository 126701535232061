import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Moment from "moment";
import apiService from "../../../http";
import { Input } from "../../../components/ui";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(payments);
    } else {
      const data = payments.filter((item) => {
        const lowerCaseTitle = item.contractor.title.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  const refreshPayments = async () => {
    try {
      const payments = await apiService.get("payment");
      setPayments(payments);
      setFilteredData(payments);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    refreshPayments();
  }, []);
  const handlePrint = (invoice) => {
    const formattedDate = Moment(invoice.createdAt).format("YYYY-MM-DD");
    const netTotal = invoice.quantity * invoice.unitPrice;
    const printContent = `
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Qty</th>
            <th>Rate</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${invoice.job.title}</td>
            <td>${invoice.quantity}</td>
            <td>${invoice.unitPrice}</td>
            <td>${netTotal.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</td>
          </tr>
        </tbody>
      </table>`;
  
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>
          table {
            border: 1px solid black;
            border-collapse: collapse;
            margin: 0 auto; /* Center the table */
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: center; /* Center text in table cells */
          }
          th {
            background-color: #f2f2f2;
          }
            img {
              width: 150px; /* Adjust the width as needed */
              display: block;
              margin: 0 auto; /* Center the logo */
            }
            .print-content {
              text-align: center;
              margin-bottom: 20px;
            }
            .print-content p {
              font-family: Sinhala, Arial;
              font-size: 15px;
              margin: 20px 0 10px 0;
            }
            h1, h3 {
              text-align: center;
            }
            .details {
              display: flex;
              justify-content: space-between;
              margin: 20px;
            }
            .left, .right {
              width: 45%;
            }
            .right {
              text-align: right;
            }
          </style>
        </head>
        <body>
          <div class="print-content">
            <img src="/logo.png" alt="Logo" />
            <p>
              තහඩු නැවීම, කැපිම, රොල් කිරීම, රූෆින් ශීට් සහ උපාංග, ස්ටීල් ෆැබ්‍රිකේශන් වැඩ
              <br>
              විලකොළදෙනිය, මලංගම, රත්නපුර
              <br>
              045 2121750, 074 0327850
            </p>
            <hr>
          </div>
    
          <h3>Pay Sheet</h3>
          <p>PaySheet Number: ${invoice.paymentId}<br>
             PaySheet Date: ${formattedDate}<br>
             Contractor ID: ${invoice.contractor.contractorId}<br>
             Contractor Title: ${invoice.contractor.title}</p>
          <br>
    
          ${printContent}
    
          <div class="details">
            <div class="left">
            </div>
            <div class="right">
              <p>Total Value: ${netTotal.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}<br></p>
            </div>
          </div>
          <p>Invoiced By Miracle Mine.</p>
        </body>
      </html>
      <script>
        window.onload = function() { 
          window.print(); 
          window.onafterprint = function() { window.close(); }; 
        };
      </script>
    `);
    printWindow.document.close();
  };
  
  const columns = [
    {
      title: "Payment Id",
      align: "left",
      width: "15%",
      render: (arg) => {
        return <div className="flex flex-col items-start">{arg.paymentId}</div>;
      },
      // sorter: (a, b) => a.paymentId.localeCompare(b.paymentId),
    },
    {
      title: "Contractor Id",
      align: "left",
      width: "15%",
      render: (arg) => {
        return <p>{arg.contractor.contractorId}</p>;
      },
      // sorter: (a, b) =>
      //   a.contractor.contractorId.localeCompare(b.contractor.contractorId),
    },
    {
      title: "Contractor Title",
      align: "left",
      width: "15%",
      render: (arg) => {
        return <p>{arg.contractor.title}</p>;
      },
      // sorter: (a, b) => a.contractor.Title.localeCompare(b.contractor.Title),
    },
    {
      title: "Job Id",
      align: "left",
      width: "15%",
      render: (arg) => {
        return <div className="flex flex-col items-start">{arg.job.jobId}</div>;
      },
    },
    {
      title: "Job Title",
      align: "left",
      width: "15%",
      render: (arg) => {
        return <div className="flex flex-col items-start">{arg.job.title}</div>;
      },
    },

    {
      title: "PaySheet",
      align: "center",
      width: "15%",
      render: (arg) => {
        return (
          <button
            onClick={() => handlePrint(arg)}
            className="px-2 py-1 text-white rounded-lg bg-secondary"
          >
            Print
          </button>
        );
      },
    },
    {
      title: "Total",
      align: "center",
      width: "20%",
      render: (arg) => {
        const formattedPrice = arg.unitPrice;
        const qty=arg.quantity;
        return formattedPrice*qty;
      },
      // sorter: (a, b) => a.total - b.total,
    },

    {
      title: "Date",
      align: "center",
      width: "20%",
      render: (arg) => {
        const date = Moment(arg.createdAt);

        const formattedDatetime = date.format("YYYY-MM-DD");

        return <p className="min-w-[80px]">{formattedDatetime}</p>;
      },
      // sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      <div className="grid grid-cols-3 gap-3 px-2 py-2 mb-3 bg-white rounded-md">
        <div></div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Payments;
