import React, { useEffect, useState } from "react";
import { Table } from "antd";
import ReactDOMServer from 'react-dom/server';
import PrintReport from "./PrintReportStock";
import Logo from "../../../components/logo/Logo";
import apiService from "../../../http";
// import { printPreview } from "../../../utils";

function StockReport() {
  const [tableData, setTableData] = useState(null);

  const getAllData = async () => {
    try {
      const productsResponse = await apiService.get("product/stock-not-zero");
      setTableData(productsResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const columns = [
    {
      title: "Product ID",
      key: "productId",
      align: "left",
      render: (args) => {
        return args.productId;
      },
      width: "100%",
    },
    {
      title: "Product Name",
      key: "title",
      render: (args) => {
        return args.title;
      },
      align: "left",
      width: "100%",
    },
    {
      title: "Stock Quantity",
      key: "stock",
      dataIndex: "stock",
      align: "center",
      width: "100%",
    },
    {
      title: "Unit Price",
      key: "unitPrice",
      align: "right",
      render: (args) => {
        const formattedPrice = args.unitPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      width: "100%",
    },
    {
      title: "Total Price",
      key: "totalPrice",
      render: (args) => {
        const total = Number(args.stock) * Number(args.unitPrice);
        const formattedPrice = total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return <p className="text-right ">{formattedPrice}</p>;
      },
      align: "right",
      width: "100%",
    },
  ];

  const customFooter = () => {
    let Total = 0;

    for (let i = 0; i < tableData?.length; i++) {
      Total += tableData[i].stock * tableData[i].unitPrice;
    }

    return (
      <div className="flex flex-row items-end justify-end w-full gap-16 pr-2 border-y-2">
        <div className="pr-4 text-lg font-bold">Total</div>
        <div className="mr-2 text-lg font-bold">
          {Total.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      </div>
    );
  };

  const tData = tableData?.map((item, index) => {
    return {
      key: index,
      ...item,
      customFooter,
    };
  });

  const handlePrintData = () => {
    const printContent = ReactDOMServer.renderToString(<PrintReport tableData={tableData} />);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(
        `<html>
            <head>
                <title>Material Stock Report</title>
                <style>
                    /* Add any styles needed for printing */
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #000;
                        padding: 8px;
                        text-align: left;
                    }
                </style>
            </head>
            <body>${printContent}</body>
        </html>`
    );
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.onafterprint = function() {
        document.body.removeChild(iframe);
    };
  };

  return (
    <div className="flex flex-col w-full gap-4 m-6">
      <div className="p-6 border-2 rounded-md">
        <div className="flex flex-col items-start">
          <p className="text-lg font-bold">LEGACY STOCK</p>
        </div>
        <div className="flex justify-between items-center w-full relative !mt-8">
          <div className="flex flex-row gap-20">
            {/* print and view button sections */}
            <div className="flex flex-col gap-2">
              <button
                className="text-white btn bg-primary btn-wide"
                onClick={handlePrintData}
              >
                PRINT
              </button>
            </div>
          </div>

          {/* Logo Component */}
          <div className="absolute top-0 right-0 flex flex-col">
            <Logo />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center w-full p-6 border-2 rounded-md">
        <Table
          columns={columns}
          dataSource={tData}
          style={{ width: "100%" }}
          footer={customFooter}
        />
      </div>
    </div>
  );
}

export default StockReport;
