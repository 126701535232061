import React from "react";

const RadioButton = ({ options, selectedOption, onClick }) => {
  return (
    <div>
      {options.map((option, index) => (
        <label key={index}>
          <input
            type="radio"
            value={option}
            checked={selectedOption === option}
            onChange={() => onClick(option)}
          />
          <span className="pr-5 pl-1">{option}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioButton;
