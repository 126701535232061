import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import HomeSection from "../Home";
import StockClear from "./StockClear";
import UserRegister from "./UserRegister";

function Index() {
  const user = useSelector((state) => state.user);

  const SuperPrivateRouter = () => {
    if (user.data.role === "super-admin") {
      return <Outlet />;
    } else {
      return <Navigate to="/" />;
    }
  };
  return (
    <Routes>
      <Route path="/user-register" element={<UserRegister />} />
      <Route element={<SuperPrivateRouter />}>
        <Route path="/stock-clear" element={<StockClear />} />
      </Route>
      <Route path="/*" element={<HomeSection />} />
    </Routes>
  );
}

export default Index;
