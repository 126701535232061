import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function CollapseItem({ item }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleMouseLeave = () => {
    if (!item.selected) {
      setIsChecked(false);
    }
  };
  const handleClick = () => {
    setIsChecked(!isChecked);
  };

  const getClassList = () => {
    let classList =
      "collapse hover:bg-gray-100 hover:text-gray-700 w-full text-white items-center ";
    classList += isChecked
      ? "bg-gray-400 text-gray-700 "
      : "text-white rounded-lg h-10";
    return classList;
  };

  return (
    <div
      className={getClassList()}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <input type="checkbox" checked={isChecked} onChange={handleClick} />
      <div className="collapse-title text-center">
        <span
          className={
            isChecked
              ? "flex gap-4"
              : "flex gap-4 -translate-y-2 -translate-x-1"
          }
        >
          <span className="mt-1">{item.icon}</span>
          <h2 className="capitalize">{item.title}</h2>
        </span>
      </div>
      <div className="collapse-content text-left -translate-y-2 !z-10">
        {item.collapseData.map((navitem, index) => (
          <NavLink to={navitem.url} key={index}>
            <div className="gap-2 hover:bg-gray-400 rounded-md p-2">
              {navitem.name}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default CollapseItem;
