import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "../../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../../http";
import { Table } from "antd";

const ProductMaterialModal = ({ handleClose, selectedJob }) => {
  const inputDataStructure = {
    quantity: {
      key: "quantity",
      label: "Quantity",
      required: true,
      min: 0,
      data: 0,
      type: "number",
      error: null,
    },

    material: {
      key: "material",
      label: "Material",
      required: true,
      optTitle: "a material",
      data: "",
      optList: [],
      type: "number",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);
  const [materials, setMaterials] = useState([]);

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;
    setInputs(input_list);
  };

  const buildForm = async () => {
    let input_list = { ...inputs };

    let materials = await apiService.get("material");

    const optList = materials.map((material) => {
      return {
        id: material.id,
        title: material.title,
      };
    });

    input_list.material.optList = optList;

    setInputs(input_list);
  };
  const refreshMaterials = async () => {
    const materialList = await apiService.get(
      `product/material/${selectedJob.id}`
    );
    setMaterials(materialList);
  };

  useEffect(() => {
    buildForm();
    refreshMaterials();
  }, []);

  const handleSubmit = async () => {
    const event = window.event;
    event.preventDefault();

    const formData = {
      id: selectedJob.id,
      material: inputs.material.data,
      quantity: inputs.quantity.data,
    };

    try {
      await apiService.post("product/material/add", formData);
      toast.success("Job materials updated successfully!");
      refreshMaterials();
    } catch (e) {
      toast.error(e);
    }
    refreshMaterials();

  };

  const actionBtns = (material) => {
    return (
      <div className="flex flex-wrap items-center">
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={async () => {
            try {
              await apiService.post("product/material/remove", {
                job: selectedJob.id,
                material: material._id,
              });
              toast.success("Product materials updated successfully!");
              refreshMaterials();
            } catch (e) {
              toast.error(e);
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      width: "5%",
      render: (arg, record, index) => {
        const rowNumber = index + 1;
        return <p className="text-center">{rowNumber}</p>;
      },
    },

    {
      title: "Material ID",
      render: (arg) => {
        return arg.materialId;
      },
      key: "materialId",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.materialId.localeCompare(b.materialId),
    },
    {
      title: "Material",
      render: (arg) => {
        return arg.title;
      },
      key: "title",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Quantity",
      dataIndex: "stockCount",
      key: "stockCount",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.stockCount.localeCompare(b.stockCount),
    },
    {
      title: "Unit price",
      align: "center",
      width: "10%",
      render: (arg) => {
        const formattedPrice = arg.unitPrice.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "Total",
      align: "center",
      width: "10%",
      render: (arg) => {
        const total = arg.unitPrice * arg.stockCount;
        const formattedPrice = total.toLocaleString("en-US", {
          style: "currency",
          currency: "LKR",
        });
        return formattedPrice;
      },
    },
    {
      title: "Actions",
      align: "center",
      width: "10%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
        <div className=" shadow-xl transition-all w-[80%] mx-auto mt-[100px] rounded-lg bg-white">
          <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
            <h3 className="py-1 mx-auto">Material center</h3>
            <span
              className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
              onClick={handleClose}
            >
              X
            </span>
          </div>
          <div className="flex mx-4 mt-2">
            <div className="w-[80%]">
              <div className="w-full">
                <h3 className="w-full text-lg text-center bg-slate-200 text-slate-500">
                  Material list
                </h3>
                <Table columns={columns} dataSource={materials} />
              </div>
            </div>
            <div className="w-[20%]">
              <h3 className="w-full mb-4 text-lg text-center bg-slate-200 text-slate-500">
                Add materials
              </h3>

              <Select input={inputs.material} handleChange={handleChange} />

              <Input input={inputs.quantity} handleChange={handleChange} />

              <Button
                handleClick={handleSubmit}
                className="w-full mt-6"
                text={"Add"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMaterialModal;
