import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FaUserCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Confirm } from "../ui";
import PasswordModal from "./PasswordModal";
import { useSelector } from "react-redux";

const NavBar = ({ dashboard }) => {
  const user = useSelector((state) => state.user);

  const [userMenu, setUserMenuState] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [passwordModalState, setPasswordModalState] = useState(false);

  const userMenuclassList = () => {
    let classList =
      "absolute z-10 right-0 w-48 mt-2 py-2 bg-white rounded-md shadow-xl transform transition-all duration-300 ";
    classList += userMenu ? "opacity-100 scale-100" : "opacity-0 scale-95";
    return classList;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isOutside = !event.target.closest(".user-menu");

      if (isOutside && userMenu) {
        setUserMenuState(false);
      }
    };

    if (userMenu) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [userMenu]);

  return (
    <div>
      <ToastContainer />
      {logoutConfirm && (
        <Confirm
          cancelHandler={() => {
            setLogoutConfirm(false);
          }}
          confirmHandler={() => {
            window.location = "/logout";
          }}
          actionLabel="OK"
          title="Logout"
          confirmMsg="Logout from the system?"
        />
      )}
      {passwordModalState && (
        <PasswordModal
          handleClose={() => {
            setPasswordModalState(false);
          }}
        />
      )}

      <nav className="header-nav z-30 sticky top-0 left-0 w-full  h-16 bg-primary border-b-2 border-slate-500">
        <div className="w-[95%] mx-auto flex justify-between items-center">
          <a className="flex" href="/">
            <img
              alt=""
              className="h-[50px] mt-2"
              src={require("../../assets/img/logo.png")}
            ></img>
          </a>
          {/* show profil section button only if looged in */}
          {user.authenticated && (
            <div
              className="relative cursor-pointer"
              onClick={() => {
                setUserMenuState(true);
              }}
            >
              <IconContext.Provider value={{ color: "white", size: "30px" }}>
                <div>
                  <FaUserCircle />
                </div>
              </IconContext.Provider>
              {userMenu && (
                <div class={userMenuclassList()}>
                  <span
                    class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    onClick={() => {
                      setPasswordModalState(true);
                    }}
                  >
                    Change password
                  </span>
                  <span
                    onClick={() => {
                      setLogoutConfirm(true);
                    }}
                    class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Logout
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
