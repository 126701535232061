import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/main.css";

import Router from "./router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

import { persistor, store } from "./redux/store";

import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </PersistGate>
    </LocalizationProvider>
  </Provider>,
  document.getElementById("root")
);
