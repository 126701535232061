// PrintReportJobCost.js

import React from "react";
import { Table } from "antd";

const PrintReportJobCost = ({ tableData }) => {
  const columns = {
    materials: [
      {
        title: "Material Id",
        key: "materialId",
        dataIndex: "materials",
        render: (materials) => {
          if (materials.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="!min-w-[90px]">{item.materialId}</p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Materials",
        key: "materials",
        dataIndex: "materials",
        render: (materials) => {
          if (materials.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="!min-w-[90px]">{item.title}</p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Materials Quantity",
        key: "materialsQty",
        dataIndex: "materials",
        render: (materials) => {
          if (materials.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="text-center">{item.stockCount}</p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Unit Price",
        key: "materialUnitPrice",
        dataIndex: "materials",
        render: (materials) => {
          if (materials.length === 0) return <p className="text-right">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="text-right">
                  {item.unitPrice.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                </p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Total Price",
        key: "materialTotalPrice",
        dataIndex: "materials",
        render: (materials) => {
          if (materials.length === 0) return <p className="text-right">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="text-right">
                  {(item.unitPrice * item.stockCount).toLocaleString("en-US", { minimumFractionDigits: 2 })}
                </p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
    ],
    contractor: [
      {
        title: "Contractor Name",
        key: "contractorName",
        dataIndex: "contractors",
        render: (contractors) => {
          if (contractors.length === 0) return <p className="text-left">No Data</p>;
          return (
            <>
              {contractors.map((item, index) => (
                <p key={index} className="text-left !min-w-[200px]">
                  {item.contractor.title}
                </p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Given Qty",
        key: "givenQty",
        dataIndex: "contractors",
        render: (contractors) => {
          if (contractors.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {contractors.map((item, index) => (
                <p key={index} className="text-center">
                  {item.given}
                </p>
              ))}
            </>
          );
        },
        align: "center",
        width: "100%",
      },
      {
        title: "Completed Qty",
        key: "completedQty",
        dataIndex: "contractors",
        render: (contractors) => {
          if (contractors.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {contractors.map((item, index) => (
                <p key={index} className="text-center">
                  {item.completed}
                </p>
              ))}
            </>
          );
        },
        align: "center",
        width: "100%",
      },
      {
        title: "Paid Qty",
        key: "paidQty",
        dataIndex: "contractors",
        render: (contractors) => {
          if (contractors.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {contractors.map((item, index) => (
                <p key={index} className="text-center">
                  {item.paid}
                </p>
              ))}
            </>
          );
        },
        align: "center",
        width: "100%",
      },
      {
        title: "Contractor Fee",
        key: "contractorFee",
        dataIndex: "product",
        render: (product) => {
          return (
            <p className="text-right">
              {product.contractorFee.toLocaleString("en-US", { minimumFractionDigits: 2 })}
            </p>
          );
        },
        align: "right",
        width: "100%",
      },
      {
        title: "Total Contractor Fee",
        key: "totalContractorFee",
        render: (record) => {
          const totalContractorFee = record.contractors.reduce((total, contractor) => {
            return total + contractor.paid * record.product.contractorFee;
          }, 0);
          return (
            <p className="text-right">
              {totalContractorFee.toLocaleString("en-US", { minimumFractionDigits: 2 })}
            </p>
          );
        },
        align: "right",
        width: "100%",
      },
    ],
  };

  // Calculate Net Total Costs
  let netMaterialCost = 0;
  let netContractorCost = 0;

  if (tableData) {
    tableData.forEach((item) => {
      item.materials.forEach((material) => {
        netMaterialCost += material.unitPrice * material.stockCount;
      });
      item.contractors.forEach((contractor) => {
        netContractorCost += contractor.paid * item.product.contractorFee;
      });
    });
  }

  return (
    <div>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src="/logo.png" alt="Logo" style={{ width: '200px' }} />
                <p style={{ fontSize: '15px', margin: '20px 0 10px 0' }}>
                Miracle Mine Investment (PVT) Ltd - Opanayaka
                </p>
                <p style={{ fontSize: '18px', margin: '0' }}>
                077 544 7294, 078 5525 667
                </p>
                <h1> Job Cost Report</h1>
            </div>
      <p className="text-lg font-bold">Material Summary</p>
      <div>
        <Table
          columns={columns.materials}
          dataSource={tableData}
          pagination={false}
          footer={() => (
            <div className="flex justify-end">
              <p className="pr-4 text-lg font-bold">Net Materials Cost:
             {netMaterialCost.toLocaleString("en-US", { minimumFractionDigits: 2 })}</p>
            </div>
          )}
        />
      </div>
      <p></p>
      <p className="text-lg font-bold">Contractor Summary</p>
      <div>
        <Table
          columns={columns.contractor}
          dataSource={tableData}
          pagination={false}
          footer={() => (
            <div className="flex justify-end">
              <p className="pr-4 text-lg font-bold">Net Contractor Cost:
              {netContractorCost.toLocaleString("en-US", { minimumFractionDigits: 2 })}</p>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default PrintReportJobCost;
