import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Select } from "../../../components/ui";
import apiService from "../../../http";

const ImportMaterialModal = ({ handleClose, noSupplier=false }) => {
    const inputDataStructure = {
        material: {
            key: "material",
            label: "Material",
            optTitle: "a material",
            data: "",
            optList: [],
            required: true,
            type: "text",
            error: null,
        },
        supplier: {
            key: "supplier",
            optTitle: "a supplier",
            label: "Supplier",
            data: "",
            optList: [],
            required: false,
            type: "text",
            error: null,
        },

        remarks: {
            key: "remarks",
            label: "Remarks",
            data: "",
            type: "text",
            error: null,
        },

        quantity: {
            key: "quantity",
            label: "Quantity",
            data: "",
            type: "number",
            required: true,
            error: null,
        },
    };

    const [inputs, setInputs] = useState(inputDataStructure);

    const handleChange = (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };

    const buildForm = async () => {
        let input_list = { ...inputs };

        const materials = await apiService.get("material");
        const suppliers = await apiService.get("supplier");

        const matOptList = materials.map((material) => {
            return {
                id: material.id,
                title: `${material.materialId} - ${material.title}`,
            };
        });
        const supOptList = suppliers.map((supplier) => {
            return {
                id: supplier.id,
                title: `${supplier.supplierId} - ${supplier.title}`,
            };
        });

        input_list.material.optList = matOptList;
        input_list.supplier.optList = supOptList;
        setInputs(input_list);
    };

    useEffect(() => {
        buildForm();
    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();

        if (inputs.quantity.data <= 0) {
            toast.error("Please enter a valid quantity!");
            return;
        }

        const formData = {
            material: inputs.material.data,
            supplier: inputs.supplier.data ? inputs.supplier.data: "No Supplier",
            remarks: inputs.remarks.data,
            quantity: inputs.quantity.data,
        };

        try {
            await apiService.post("material/import", formData);
            toast.success("Material imported successfully!");

            handleClose();
        } catch (e) {
            toast.error(e);
        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-y-auto">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="w-full bg-primary text-white text-xl mb-6 flex items-center justify-between">
                        <h3 className="mx-auto py-1">Import material</h3>
                        <span
                            className="bg-slate-500 hover:bg-secondary  px-3 py-1 cursor-pointer"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mt-2 mx-4">
                        <Select
                            input={inputs.material}
                            handleChange={handleChange}
                        />
                        {!noSupplier && <Select
                            input={inputs.supplier}
                            handleChange={handleChange}
                        />}
                        <Input
                            input={inputs.remarks}
                            handleChange={handleChange}
                        />

                        <Input
                            input={inputs.quantity}
                            handleChange={handleChange}
                        />

                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text={"Import"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportMaterialModal;
