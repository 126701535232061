import React, { useEffect, useState } from "react";
import { Input, Button } from "../../../components/ui";
import { toast } from "react-toastify";
import apiService from "../../../http";

const ProductModal = ({ handleClose, selectedProduct }) => {
    const inputDataStructure = {
        finishStock: {
            key: "finishStock",
            required: true,
            label: "Enter Finish Quantity",
            data: "",
            type: "number",
            error: null,
        },

       
    };

    const [inputs, setInputs] = useState(inputDataStructure);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async (input) => {
        let input_list = { ...inputs };
        input_list[input.key] = input;

        setInputs(input_list);
    };



    useEffect(() => {

    }, []);

    const handleSubmit = async () => {
        const event = window.event;
        event.preventDefault();



        try {
            setIsLoading(true);

            if (selectedProduct) {
                const data = {
                    finishStock: inputs.finishStock.data,
                    id : selectedProduct.id
                };
                await apiService.patch("product/finish", data);
                toast.success("Product updated successfully!");
            } 

            handleClose();
            setIsLoading(false);

        } catch (e) {
            toast.error(e);
            setIsLoading(false);

        }
    };

    return (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 overflow-y-auto transition-opacity bg-gray-500 bg-opacity-75 ">
                <div className=" shadow-xl transition-all w-[25%] mx-auto mt-[100px] rounded-lg bg-white">
                    <div className="flex items-center justify-between w-full mb-6 text-xl text-white bg-primary">
                        <h3 className="py-1 mx-auto">
                        Finish Stock
                        </h3>
                        <span
                            className="px-3 py-1 cursor-pointer bg-slate-500 hover:bg-secondary"
                            onClick={handleClose}
                        >
                            X
                        </span>
                    </div>
                    <div className="mx-4 mt-2">
                        <Input
                            input={inputs.finishStock}
                            handleChange={handleChange}
                        />
                        {isLoading ? (
                        "Wait..."
                    ) : (
                        <Button
                            handleClick={handleSubmit}
                            className="mt-6"
                            text="Finish"
                        />)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
