import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Logo from "../../../components/logo/Logo";
import SelectProduct from "../../../components/ui/SelectProduct";
import apiService from "../../../http";
import { buildContractStatus, printPreview } from "../../../utils/";
import ReactDOMServer from "react-dom/server";
import PrintReport from "./PrintReportJobCost"; 

function JobCostReport() {
  const [transactionStatus, setTransactionStatus] = useState([]);
  const [transactionSummary, setTransactionSummary] = useState({});
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobsData, setJobsData] = useState(null);
  const [tableData, setTableData] = useState(null);

  const getContractData = async () => {
    const transactionList = await apiService.get(
      `transaction/${selectedJob.id}`
    );
    const paymentList = await apiService.get(`payment/${selectedJob.id}`);  

    buildContractStatus(
      transactionList,
      paymentList,
      setTransactionStatus,
      setTransactionSummary
    );
  };

  const getAllData = async () => {
    try {
      const jobResponse = await apiService.get("job");
      setJobsData(jobResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (selectedJob) {
      getContractData();
    }
  }, [selectedJob]);

  const columns = {
    materials: [
      {
        title: "Material Id",
        key: "materialId",
        render: (args) => {
          const materials = args.materials || [];
          if (materials.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="!min-w-[90px]">{item.materialId}</p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Materials",
        key: "materials",
        render: (args) => {
          const materials = args.materials || [];
          if (materials.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="!min-w-[90px]">{item.title}</p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Materials Quantity",
        key: "materialsQty",
        render: (args) => {
          const materials = args.materials || [];
          if (materials.length === 0) return <p className="text-center">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="text-center">{item.stockCount}</p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Unit Price",
        key: "materialUnitPrice",
        render: (args) => {
          const materials = args.materials || [];
          if (materials.length === 0) return <p className="text-right">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="text-right">
                  {item.unitPrice.toLocaleString("en-US", { minimumFractionDigits: 2 })}
                </p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Total Price",
        key: "materialTotalPrice",
        render: (args) => {
          const materials = args.materials || [];
          if (materials.length === 0) return <p className="text-right">No Data</p>;
          return (
            <>
              {materials.map((item, index) => (
                <p key={index} className="text-right">
                  {(item.unitPrice * item.stockCount).toLocaleString("en-US", { minimumFractionDigits: 2 })}
                </p>
              ))}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
    ],
    contractor: [
      {
        title: "Contractor Name",
        key: "contractorName",
        render: (args) => {
          const materialTotalPrice = args.contractors.map((item, index) => (
            <p key={index} className="text-left !min-w-[200px]">
              {item.contractor.title}
            </p>
          ));
          return (
            <>
              {materialTotalPrice.length === 0 ? (
                <p className="text-left">No Data</p>
              ) : (
                materialTotalPrice
              )}
            </>
          );
        },
        align: "left",
        width: "100%",
      },
      {
        title: "Given Qty",
        key: "givenQty",
        render: (args) => {
          const materialTotalPrice = args.contractors.map((item, index) => (
            <p key={index} className="text-center">
              {item.given}
            </p>
          ));
          return (
            <>
              {materialTotalPrice.length === 0 ? (
                <p className="text-center">No Data</p>
              ) : (
                materialTotalPrice
              )}
            </>
          );
        },
        align: "center",
        width: "100%",
      },
      {
        title: "Completed Qty",
        key: "completedQty",
        render: (args) => {
          const materialTotalPrice = args.contractors.map((item, index) => (
            <p key={index} className="text-center">
              {item.completed}
            </p>
          ));
          return (
            <>
              {materialTotalPrice.length === 0 ? (
                <p className="text-center">No Data</p>
              ) : (
                materialTotalPrice
              )}
            </>
          );
        },
        align: "center",
        width: "100%",
      },
      {
        title: "Paid Qty",
        key: "paidQty",
        render: (args) => {
          const materialTotalPrice = args.contractors.map((item, index) => (
            <p key={index} className="text-center">
              {item.paid}
            </p>
          ));
          return (
            <>
              {materialTotalPrice.length === 0 ? (
                <p className="text-center">No Data</p>
              ) : (
                materialTotalPrice
              )}
            </>
          );
        },
        align: "center",
        width: "100%",
      },
      {
        title: "Contractor Fee",
        key: "contractorFee",
        render: (args) => {
          const materialTotalPrice = args.contractors.map((item, index) => (
            <p key={index} className="text-right">
              {args.product.contractorFee.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
            </p>
          ));
          return (
            <>
              {materialTotalPrice.length === 0 ? (
                <p className="text-right">No Data</p>
              ) : (
                materialTotalPrice
              )}
            </>
          );
        },
        align: "right",
        width: "100%",
      },
      {
        title: "Total Contractor Fee",
        key: "totalContractorFee",
        render: (args) => {
          const materialTotalPrice = args.contractors.map((item, index) => (
            <p key={index} className="text-right">
              {(args.product.contractorFee * item.paid).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                }
              )}
            </p>
          ));
          return (
            <>
              {materialTotalPrice.length === 0 ? (
                <p className="text-right">No Data</p>
              ) : (
                materialTotalPrice
              )}
            </>
          );
        },
        align: "right",
        width: "100%",
      },
    ],
  };

  let totalNetCost = 0;
  const customFooter = (tableName) => {
    let netCost = 0;
  
    if (tableData !== null) {
      if (tableName === "materials") {
        tableData.forEach((item) => {
          const materials = item.materials || [];
          materials.forEach((material) => {
            netCost += material.unitPrice * material.stockCount;
          });
        });
      } else if (tableName === "contractor") {
        tableData.forEach((item) => {
          const contractors = item.contractors || [];
          contractors.forEach((contractor) => {
            netCost += contractor.paid * item.product.contractorFee;
          });
        });
      }
    }
  
    return (
      <div className="flex flex-row items-end justify-end w-full gap-16 pr-2 border-y-2">
        {tableName === "materials" && (
          <div className="flex justify-between">
            <div className="pr-4 text-lg font-bold">Net Materials Cost</div>
            <div className="mr-2 text-lg font-bold">
              {netCost.toLocaleString("en-US", { minimumFractionDigits: 2 })}
            </div>
          </div>
        )}
        {tableName === "contractor" && (
          <div className="flex justify-between">
            <div className="pr-4 text-lg font-bold">Net Contractor Cost</div>
            <div className="mr-2 text-lg font-bold">
              {netCost.toLocaleString("en-US", { minimumFractionDigits: 2 })}
            </div>
          </div>
        )}
      </div>
    );
  };
  

  const handlePrintData = async () => {
    try {
      const jobResponse = await apiService.get(`job/${selectedJob.id}`);
      if (jobResponse.length !== 0) {
        const printContent = ReactDOMServer.renderToString(
          <PrintReport tableData={tableData} />
        );
        const iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.style.position = "absolute";
        iframe.style.width = "0px";
        iframe.style.height = "0px";
        iframe.style.border = "none";
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(
          `<html>
              <head>
                  <title>Job Cost Report</title>
                  <style>
                      /* Add any styles needed for printing */
                      table {
                          width: 100%;
                          border-collapse: collapse;
                      }
                      th, td {
                          border: 1px solid #000;
                          padding: 8px;
                          text-align: left;
                      }
                  </style>
              </head>
              <body>${printContent}</body>
          </html>`
        );
        doc.close();
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        iframe.onafterprint = function () {
          document.body.removeChild(iframe);
        };
      } else {
        toast.error("There are no data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnViewClicked = async () => {
    if (!selectedJob) {
      toast.error("Please select a job first.");
      return;
    }
    try {
      const jobsResponse = await apiService.get(`job/${selectedJob.id}`);
      const jobsList = [];
      jobsList.push(jobsResponse);
      jobsList.push(transactionSummary);
      jobsList.push(transactionStatus);
  
      const rearrangedForTable = [
        {
          ...jobsList[0],
          materials: jobsList[0].materials || [],
          contractors: jobsList[2] || [],
        },
      ];
  
      setTableData(rearrangedForTable);
    } catch (err) {
      console.log(err);
    }
  };
  
  
  const tData = tableData?.map((item, index) => {
    return {
      key: index,
      ...item,
      customFooter,
    };
  });

  return (
    <>
      <div className="flex flex-col w-full gap-4 m-6">
        <div className="p-6 border-2 rounded-md">
          <div className="flex flex-col items-start">
            <p className="text-lg font-bold">JOB COST</p>
            <div className="flex justify-between items-center w-full relative !mt-8">
              <div className="flex flex-row gap-20">
                <SelectProduct
                  reportName={"JOB-COST"}
                  titleData={"Select Job"}
                  ListOfData={jobsData}
                  selectedValue={selectedJob}
                  handleSelectedValue={setSelectedJob}
                />
                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="text-white btn bg-primary btn-wide"
                    onClick={() => handlePrintData(selectedJob.id)}
                  >
                    PRINT
                  </button>
                  <button
                    className="text-white btn bg-primary btn-wide"
                    onClick={handleOnViewClicked}
                  >
                    VIEW
                  </button>
                </div>
              </div>

              {/* Logo Component */}
              <div className="absolute top-0 right-0 flex flex-col">
                <Logo />
              </div>
            </div>
            {selectedJob && (
              <div className="flex items-center gap-5 align-middle">
                Product:
                <p className="px-5 text-lg font-bold border-2 rounded-lg bg-primary bg-opacity-10 border-primary">
                  {selectedJob?.product.title}
                </p>
              </div>
            )}
          </div>
        </div>
        <p className="pl-5 text-lg font-bold">Material Summary</p>
        <div className="flex flex-col items-center w-full p-6 border-2 rounded-md">
          <Table
            columns={columns.materials}
            dataSource={tData}
            style={{ width: "100%" }}
            footer={() => customFooter("materials")}
          />
        </div>
        <p className="pl-5 text-lg font-bold">Contractor Summary</p>
        <div className="flex flex-col items-center w-full p-6 border-2 rounded-md">
          <Table
            columns={columns.contractor}
            dataSource={tData}
            style={{ width: "100%" }}
            footer={() => customFooter("contractor")}
          />
        </div>
      </div>
    </>
  );
}

export default JobCostReport;
