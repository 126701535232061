import { Table } from "antd";
import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
import Logo from "../../../components/logo/Logo";
import SelectProduct from "../../../components/ui/SelectProduct";
import apiService from "../../../http";
// import { printPreview } from "../../../utils";
import ReactDOMServer from 'react-dom/server';
import PrintReport from "./PrintReportProducts"; // Import the PrintReport component

function ProductReport() {
  const [productsData, setProductsData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [tableData, setTableData] = useState(null);

  const getAllData = async () => {
    try {
      const productResponse = await apiService.get("product");
      setProductsData(productResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const columns = [
    {
      title: "Job Id",
      key: "jobId",
      dataIndex: "jobId",
      align: "left",
      width: "100%",
    },
    {
      title: "Product ID",
      key: "productId",
      align: "left",
      render: (args) => {
        return args.product.productId;
      },
      width: "100%",
    },
    {
      title: "Product Name",
      key: "title",
      render: (args) => {
        return args.product.title;
      },
      align: "left",
      width: "100%",
    },
    {
      title: "Contractor Fee",
      key: "contractorFee",
      render: (args) => {
        const formattedPrice = args.product.contractorFee.toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 2,
          }
        );
        return formattedPrice;
      },
      align: "right",
      width: "100%",
    },
    {
      title: "Finished Quantity",
      key: "finishedQuantity",
      dataIndex: "finishedQuantity",
      align: "center",
      width: "100%",
    },
    {
      title: "Unit Price",
      key: "unitPrice",
      align: "right",
      render: (args) => {
        const formattedPrice = args.product.unitPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return formattedPrice;
      },
      width: "100%",
    },
    {
      title: "Total Price",
      key: "totalPrice",
      render: (args) => {
        const total =
          Number(args.finishedQuantity) * Number(args.product.unitPrice);
        const formattedPrice = total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        return <p className="text-right ">{formattedPrice}</p>;
      },
      align: "right",
      width: "100%",
    },
  ];

  const customFooter = () => {
    let totalFinishedQty = 0;
    let TotalNetPrice = 0;

    for (let i = 0; i < tableData?.length; i++) {
      totalFinishedQty += tableData[i]["finishedQuantity"];
      TotalNetPrice +=
        tableData[i]["finishedQuantity"] * tableData[i]["product"]["unitPrice"];
    }

    return (
      <div className="flex flex-row items-end justify-end w-full gap-16 pr-2 border-y-2">
        <div className="pr-4 text-lg font-bold">Total of Finished Qty</div>
        <div className="pl-4 pr-16 text-lg font-bold">{totalFinishedQty}</div>
        <div className="pr-4 text-lg font-bold">Total Net Price</div>
        <div className="mr-2 text-lg font-bold">
          {TotalNetPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}
        </div>
      </div>
    );
  };

  const handlePrintData = () => {
    const printContent = ReactDOMServer.renderToString(<PrintReport tableData={tableData} />);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(
      `<html>
        <head>
          <title>Product Job Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>${printContent}</body>
      </html>`
    );
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.onafterprint = function() {
      document.body.removeChild(iframe);
    };
  };

  const handleOnViewClicked = async () => {
    try {
      const jobsResponse = await apiService.get(
        `job/products/${selectedProduct.id}`
      );

      const filteredTableData = jobsResponse.filter(
        (item) => item.makeQuantity !== item.finishedQuantity
      );
      setTableData(filteredTableData);
    } catch (err) {
      console.log(err);
    }
  };

  const tData = tableData?.map((item, index) => {
    return {
      key: index,
      ...item,
      customFooter,
    };
  });

  return (
    <>
      <div className="flex flex-col w-full gap-4 m-6">
        <div className="p-6 border-2 rounded-md">
          <div className="flex flex-col items-start">
            <p className="text-lg font-bold">PRODUCT-JOB STOCK</p>
            <div className="flex justify-between items-center w-full relative !mt-8">
              <div className="flex flex-row gap-20">
                <SelectProduct
                  reportName={"PRODUCT-JOB-STOCK"}
                  titleData={"Select Product"}
                  ListOfData={productsData}
                  selectedValue={selectedProduct}
                  handleSelectedValue={setSelectedProduct}
                />
                {/* print and view button sections */}
                <div className="flex flex-col gap-2">
                  <button
                    className="text-white btn bg-primary btn-wide"
                    onClick={() => handlePrintData(selectedProduct.id)}
                  >
                    PRINT
                  </button>
                  <button
                    className="text-white btn bg-primary btn-wide"
                    onClick={handleOnViewClicked}
                  >
                    VIEW
                  </button>
                </div>
              </div>

              {/* Logo Component */}
              <div className="absolute top-0 right-0 flex flex-col">
                <Logo />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-full p-6 border-2 rounded-md">
          <Table
            columns={columns}
            dataSource={tData}
            style={{ width: "100%" }}
            footer={customFooter}
          />
        </div>
      </div>
    </>
  );
}

export default ProductReport;
