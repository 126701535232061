import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import ContractorModal from "./ContractorModal";
import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";

const Contractors = () => {
  const [contractors, setContractors] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataModalState, setDataModalState] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleEdit = (contractor) => {
    setSelectedContractor(contractor);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("contractor/" + selectedContractor.id);
      setConfirmModalState(false);
      refreshContractors();
      setSelectedContractor(null);
      toast.success("Contractor deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleCloseModal = () => {
    refreshContractors();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedContractor(null);
  };

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(contractors);
    } else {
      const data = contractors.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  const refreshContractors = async () => {
    try {
      const contractors = await apiService.get("contractor");
      setContractors(contractors);
      setFilteredData(contractors);
    } catch (e) {
      toast.error(e);
    }
  };

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  useEffect(() => {
    refreshContractors();
  }, []);

  const actionBtns = (contractor) => {
    return (
      <div className="flex">
        {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(contractor)}
                /> */}
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedContractor(contractor);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedContractor(contractor);
            handleEdit(contractor);
          }}
        />
      </div>
    );
  };

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            await apiService.patch(`contractor/${item.id}`);
            refreshContractors();
          } catch (e) {
            console.log(e);
          }
        }}
        className="text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
      >
        {status}
      </span>
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "contractorId",
      key: "contractorId",
      align: "left",
      width: "10%",
      // sorter: (a, b) => a.contractorId.localeCompare(b.contractorId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "20%",
      // sorter: (a, b) => a.title.localeCompare(b.title),
    },
    // {
    //     title: "Remarks",
    //     align: "center",
    //     width: "20%",
    //     dataIndex: "remarks",
    //     key: "remarks",
    // },
    {
      title: "Phone Number",
      align: "left",
      width: "20%",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      align: "left",
      width: "20%",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Status",
      align: "center",
      width: "20%",
      render: (arg) => status(arg),
    },
    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <ContractorModal
          selectedContractor={selectedContractor}
          handleClose={handleCloseModal}
        />
      )}

      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"Add new contractor"}
            handleClick={() => setDataModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Contractors;
