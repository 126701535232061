import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import CustomerModal from "./CustomerModal";
import { Confirm, Button, Input } from "../../../components/ui";
import apiService from "../../../http";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dataModalState, setDataModalState] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [confirmModalState, setConfirmModalState] = useState(false);

  const inputDataStructure = {
    searchString: {
      key: "searchString",
      label: "",
      data: "",
      type: "text",
      placeholder: "Search an item",
      error: null,
    },
  };

  const [inputs, setInputs] = useState(inputDataStructure);

  const handleEdit = (customer) => {
    setSelectedCustomer(customer);
    setDataModalState(true);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete("customer/" + selectedCustomer.id);
      setConfirmModalState(false);
      refreshCustomers();
      setSelectedCustomer(null);
      toast.success("Customer deleted successfully");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleChange = (input) => {
    let input_list = { ...inputs };
    input_list[input.key] = input;

    if (input.data === "") {
      setFilteredData(customers);
    } else {
      const data = customers.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseInputData = input.data.toLowerCase();
        return lowerCaseTitle.includes(lowerCaseInputData);
      });

      setFilteredData(data);
    }

    setInputs(input_list);
  };

  const handleCloseModal = () => {
    refreshCustomers();
    setDataModalState(false);
    setConfirmModalState(false);
    setSelectedCustomer(null);
  };

  const refreshCustomers = async () => {
    try {
      const customers = await apiService.get("customer");
      setCustomers(customers);
      setFilteredData(customers);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    refreshCustomers();
  }, []);

  const status = (item) => {
    let status;

    if (item.status) {
      status = "Active";
    } else {
      status = "Inactive";
    }

    return (
      <span
        onClick={async () => {
          try {
            await apiService.patch(`customer/${item.id}`);
            refreshCustomers();
          } catch (e) {
            console.log(e);
          }
        }}
        className="text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg cursor-pointer"
      >
        {status}
      </span>
    );
  };

  const actionBtns = (customer) => {
    return (
      <div className="flex">
        {/* <Button
                    text="View"
                    className={
                        "ml-3 hover:bg-primary hover:text-white px-[14px]"
                    }
                    handleClick={() => handleEdit(customer)}
                /> */}
        <Button
          text="Delete"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedCustomer(customer);
            setConfirmModalState(true);
          }}
        />

        <Button
          text="Edit"
          className={"ml-3 hover:bg-primary hover:text-white px-[14px]"}
          handleClick={() => {
            setSelectedCustomer(customer);
            handleEdit(customer);
          }}
        />
      </div>
    );
  };

  // const specifications = (specs) => {
  //     const classes =
  //         "text-center text-white bg-secondary px-2 py-1 mt-1 ml-2 rounded-lg";

  //     return (
  //         <div className="flex flex-col gap-2 items-start">
  //             {specs.thickness && (
  //                 <p className={classes}>Thickness: {specs.thickness} mm</p>
  //             )}
  //             {specs.length && (
  //                 <p className={classes}>Length: {specs.length} ft</p>
  //             )}
  //             {specs.width && (
  //                 <p className={classes}>Width: {specs.width} ft</p>
  //             )}
  //             {specs.diameter && (
  //                 <p className={classes}>Diameter: {specs.diameter} in</p>
  //             )}
  //             {specs.weight && (
  //                 <p className={classes}>Weight: {specs.weight} kg</p>
  //             )}
  //         </div>
  //     );
  // };
  // const stockCount = (customer) => {
  //     if (customer.priceCalcMethod === "weight-based") {
  //         return customer.stock + " kg";
  //     } else if (customer.priceCalcMethod === "quantity-based") {
  //         return customer.stock;
  //     } else if (customer.priceCalcMethod === "length-based") {
  //         const lengthPerQuantity = customer.specifications.length;
  //         const fullCount = Math.floor(customer.stock / lengthPerQuantity);
  //         const remainingCount = customer.stock % lengthPerQuantity;

  //         return (
  //             <div className="flex flex-col items-start">
  //                 <p>{"Full : " + fullCount}</p>
  //                 <p>{"Remaining : " + remainingCount + " ft"}</p>
  //             </div>
  //         );
  //     }
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      align: "left",
      width: "10%",
      // sorter: (a, b) => a.customerId.localeCompare(b.customerId),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "20%",
    },
    {
      title: "Root",
      align: "left",
      width: "20%",
      render: (arg) => {
        return arg.root.title;
      },
      // sorter: (a, b) => a.root.id.localeCompare(b.root.id),
    },
    // {
    //     title: "Remarks",
    //     align: "center",
    //     width: "20%",
    //     dataIndex: "remarks",
    //     key: "remarks",
    // },
    {
      title: "Phone Number",
      align: "left",
      width: "20%",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      align: "left",
      width: "30%",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Status",
      align: "center",
      width: "20%",
      render: (arg) => status(arg),
    },

    {
      title: "Operations",
      align: "center",
      width: "20%",
      render: (arg) => actionBtns(arg),
    },
  ];

  const tBody = filteredData.map((item, index) => {
    return {
      key: index,
      ...item,
    };
  });

  return (
    <section className="w-full mt-6">
      {dataModalState && (
        <CustomerModal
          selectedCustomer={selectedCustomer}
          handleClose={handleCloseModal}
        />
      )}

      {confirmModalState && (
        <Confirm
          cancelHandler={handleCloseModal}
          confirmHandler={handleDelete}
        />
      )}

      <div className="grid grid-cols-3 gap-3 bg-white px-2 py-2 mb-3 rounded-md">
        <div>
          <Button
            text={"Add new customer"}
            handleClick={() => setDataModalState(true)}
          />
        </div>
        <div></div>
        <div>
          <Input input={inputs.searchString} handleChange={handleChange} />
        </div>
      </div>
      <Table columns={columns} dataSource={tBody} style={{ width: "100%" }} />
    </section>
  );
};

export default Customers;
