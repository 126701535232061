import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const getCurrentYear = () => {
    const today = new Date();
    return today.getFullYear() + "  ";
  };
  return (
    <footer className="absolute bottom-0 mt-5 bg-primary w-full py-1 right-0 left-0">
      <p className="text-center text-white text-xs">
        © {getCurrentYear()} Miracle Mine | A solution developed by ALDTAN
      </p>
    </footer>
  );
};

export default Footer;
